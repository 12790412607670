import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import DefaultTxtfield from '../Txtfield/DefaultTxtfield';
import DefaultSelect from '../Select/DefaultSelect';
import { db } from '../../Firebase/Firebase';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import LoadingDialog from './LoadingDialog';
import moment from 'moment';

function CreateAccountDialog({open,onClose,}) {
    const DialogContentSx = {
        display: "flex",
        flexDirection: "column",
        gap: "20px"
    };

    const accountOptions = [
        { value: "1", label: "부대표" },
        { value: "2", label: "일반" },
    ];

    const [selected, setSelected] = useState("2");
    const [newId, setNewId] = useState("");
    const [newPw, setNewPw] = useState("");
    const [loading,setLoading]=useState(false);

    const onChange = (e) => {
        const sel = e.target.value;
        setSelected(sel);
    };

    // 아이디 입력 시 영문자와 숫자만 입력 가능하게 설정
    const onChangeId = (e) => {
        const id = e.target.value;
        if (/^[a-zA-Z0-9]*$/.test(id)) {
            setNewId(id);  // 영문자와 숫자만 설정
        }
    };

    // 비밀번호 입력 시 영문자와 숫자만 입력 가능하게 설정
    const onChangePw = (e) => {
        const pw = e.target.value;
        if (/^[a-zA-Z0-9]*$/.test(pw)) {
            setNewPw(pw);  // 영문자와 숫자만 설정
        }
    };

    // Firestore에 계정 정보 저장하는 함수
    const onCreateAccount = async () => {
        if (newId && newPw) {
            setLoading(true)
            try {
                await addDoc(collection(db, 'account'), {
                    id: newId,
                    pw: newPw,
                    grade: selected,
                    createDt: moment(new Date()).format("YYYY-MM-DD HH:MM:SS") // 생성일자
                });
                alert("계정이 성공적으로 생성되었습니다!");
                onClose()
                setNewId("")
                setNewPw("")
                
            } catch (error) {
                console.error("계정 생성 중 오류 발생: ", error);
                alert("계정 생성에 실패했습니다. 다시 시도해주세요.");
            }
            setLoading(false)
        } else {
            alert("아이디와 비밀번호를 입력해주세요.");
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <LoadingDialog open={loading}/>
            <DialogTitle>계정 생성</DialogTitle>
            <DialogContent sx={DialogContentSx}>
                <DefaultTxtfield placeholder='아이디' value={newId} onChange={onChangeId} />
                <DefaultTxtfield 
                // type='password'
                placeholder='비밀번호' value={newPw} onChange={onChangePw} />
                <DefaultSelect options={accountOptions} value={selected} onChange={onChange} />
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={onClose}>닫기</Button>
                <Button variant='contained' onClick={onCreateAccount}>생성</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateAccountDialog;
