import { TextField } from '@mui/material';
import React from 'react';

function SearchBar({placeholder="",value,onChange}) {
    return (
        <TextField
        size='small'
        fullWidth
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        />
    );
}

export default SearchBar;