import React, { useState } from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import DefaultTxtfield from '../Txtfield/DefaultTxtfield';

const years = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i); // 최근 100년
const months = Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0')); // 01월~12월
const days = Array.from({ length: 31 }, (_, i) => String(i + 1).padStart(2, '0')); // 01일~31일

function DateOfBirthPicker({year,month,day,handleYearChange,handleMonthChange,handleDayChange}) {


    return (
        <div style={{ display: 'flex', gap: '10px',width:"100%" }}>
            <FormControl >
                <InputLabel>년도</InputLabel>
                <Select 
                size='small'
                sx={{
                    width:"120px"
                }}
                value={year} onChange={handleYearChange}>
                    {years.map((year) => (
                        <MenuItem key={year} value={year}>
                            {year}년
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl>
                <InputLabel>월</InputLabel>
                <Select 
                size='small'
                sx={{
                    width:"120px"
                }}
                value={month} onChange={handleMonthChange}>
                    {months.map((month) => (
                        <MenuItem key={month} value={month}>
                            {month}월
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl>
                <InputLabel>일</InputLabel>
                <Select 
                size='small'
                sx={{
                    width:"120px"
                }}
                value={day} onChange={handleDayChange}>
                    {days.map((day) => (
                        <MenuItem key={day} value={day}>
                            {day}일
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {/* <DefaultTxtfield placeholder='년도'/>
            <DefaultTxtfield placeholder='월'/>
            <DefaultTxtfield placeholder='일'/> */}
            </div>
    );
}

export default DateOfBirthPicker;
