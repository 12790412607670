import { CircularProgress, Dialog, DialogContent } from '@mui/material';
import React from 'react';

function LoadingDialog({open}) {
    return (
        <Dialog open={open}>
            <DialogContent>
                <CircularProgress/>
            </DialogContent>
        </Dialog>
    );
}

export default LoadingDialog;