import React, { useEffect, useState } from 'react';
import AccountTable from '../Component/Table/AccountTable';
import { Button } from '@mui/material';
import './Account.css';
import CreateAccountDialog from '../Component/Dialog/CreateAccountDialog';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../Firebase/Firebase';
import SearchBar from '../Component/Txtfield/SearchBar';
import DefaultSelect from '../Component/Select/DefaultSelect';
import DefaultDatePicker from '../Component/Txtfield/DefaultDatePicker';
import moment from 'moment';
import LoadingDialog from '../Component/Dialog/LoadingDialog';
import EditAccountDialog from '../Component/Dialog/EditAccountDialog';

function Account(props) {
    const [openCreate, setOpenCreate] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [accountSearch, setAccountSearch] = useState("");
    const [passwordSearch, setPasswordSearch] = useState("");
    const [selectedGrade, setSelectedGrade] = useState("2");
    const [selectedDate, setSelectedDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    // const [selectedDate, setSelectedDate] = useState(null);
    const [loading,setLoading]=useState(false) 
    const [editContent,setEditContent]=useState(null)
    const accountOptions = [
        { value: "1", label: "부대표" },
        { value: "2", label: "일반" },
    ];

    // Firestore에서 account 컬렉션 데이터를 가져오는 함수
    const getDatas = async () => {
      
       

        try {
              setLoading(true)
            let accountQuery = collection(db, 'account');

            let q = query(accountQuery);
    
    
            if (selectedGrade) {
                q = query(q, where("grade", "==", selectedGrade));
            }
    
            const querySnapshot = await getDocs(q);
            const accountList = querySnapshot.docs.map((doc) => ({
                docId: doc.id,
                ...doc.data(),
            }));
           
            const filtered=accountList.filter(d=>d.id.includes(accountSearch)&&d.pw.includes(passwordSearch)&&d.createDt.includes(selectedDate))
            setAccounts(filtered);
            setLoading(false)
        } catch (error) {
            console.error("데이터를 가져오는 중 오류 발생: ", error);
        }
    };

    useEffect(() => {
        getDatas(); // 컴포넌트가 마운트될 때 데이터를 가져옵니다.
    }, []);

    const onClose = async () => {
        setOpenCreate(false);
        getDatas(); // 계정 생성 후 다시 데이터를 가져옵니다.
    };
    const onEditClose = async () => {
        setEditContent(null)
        getDatas(); // 계정 생성 후 다시 데이터를 가져옵니다.
    };

    const onOpen = async () => {
        setOpenCreate(true);
    };

    const handleSearch = () => {
        getDatas(); // 검색 조건에 맞는 데이터를 가져옵니다.
    };
const handleRowClick=(row)=>{
    setEditContent(row)
}
    return (
        <div className='content'>
            {/* 계정 생성 창 */}
            <LoadingDialog open={loading}/>
            <CreateAccountDialog open={openCreate} onClose={onClose} />
            {/* 계정 수정 창 */}
            <EditAccountDialog open={editContent} onClose={onEditClose} content={editContent}/>
           
            <div className="create-row">
                <Button
                    variant='contained'
                    onClick={onOpen}
                >
                    계정 생성
                </Button>
            </div>
            <div className="search-row">
                <DefaultDatePicker value={selectedDate} onChange={
                    (e)=>{
                        setSelectedDate(e.target.value)
                    }
                    } />
                <DefaultSelect
                    options={accountOptions}
                    value={selectedGrade}
                    onChange={(e) => setSelectedGrade(e.target.value)}
                />
                <SearchBar placeholder='계정' value={accountSearch} onChange={(e) => setAccountSearch(e.target.value)} />
                <SearchBar placeholder='비밀번호' value={passwordSearch} onChange={(e) => setPasswordSearch(e.target.value)} />
                <Button variant='contained' onClick={handleSearch}>
                    검색
                </Button>
            </div>
            <AccountTable 
            handleRowClick={handleRowClick}
            datas={accounts} />
        </div>
    );
}

export default Account;
