import React, { useState, useEffect, useRef } from 'react';
import NoticeTable from './NoticeTable';
import { Button } from '@mui/material';
import './Notice.css';
import CreateNoticeDialog from '../Component/Dialog/CreateNoticeDialog';
import { collection, getDocs, doc, getDoc, query, orderBy } from "firebase/firestore"; // orderBy 추가
import { db } from '../Firebase/Firebase'; // Firebase 설정 파일에서 db import
import DefaultTxtfield from '../Component/Txtfield/DefaultTxtfield'; // 검색 입력 필드
import { ITEMS_PER_PAGE } from '../const/value';
import LoadingDialog from '../Component/Dialog/LoadingDialog';

function Notice(props) {
    const [open, setOpen] = useState(false);
    const [datas, setDatas] = useState([]); // Firestore에서 불러올 전체 데이터를 저장할 상태
    const [filteredDatas, setFilteredDatas] = useState([]); // 검색 또는 필터링된 데이터를 저장할 상태
    const [searchTxt, setSearchTxt] = useState(''); // 검색어 상태
    const [hasMore, setHasMore] = useState(true); // 데이터가 더 있는지 확인
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const tableContainerRef = useRef(null);

    // 공지사항 작성 다이얼로그 열기
    const onOpen = () => {
        setOpen(true);
    };

    // 공지사항 작성 다이얼로그 닫기
    const onClose = () => {
        setOpen(false);
    };

    // Firestore에서 공지사항 데이터 불러오기 (날짜순 정렬)
    const fetchNotices = async () => {
        try {
            setLoading(true);
            // 날짜순으로 정렬된 쿼리 사용
            const noticeQuery = query(collection(db, "notice"), orderBy("createdAt", "desc")); // 내림차순 정렬
            const querySnapshot = await getDocs(noticeQuery);
            const noticeList = await Promise.all(
                querySnapshot.docs.map(async (document) => {
                    const noticeData = document.data();
                    let authorName = "";

                    if (noticeData.authorType === "admin") {
                        authorName = "관리자";
                    } else if (noticeData.authorType === "company") {
                        const companyDocRef = doc(db, "company", noticeData.authorId);
                        const companyDocSnap = await getDoc(companyDocRef);
                        authorName = companyDocSnap.exists() ? companyDocSnap.data().companyName : "Unknown Company";
                    } else if (noticeData.authorType === "fc") {
                        const fcDocRef = doc(db, "fc", noticeData.authorId);
                        const fcDocSnap = await getDoc(fcDocRef);
                        authorName = fcDocSnap.exists() ? fcDocSnap.data().fcName : "Unknown FC";
                    }

                    return {
                        id: document.id,
                        ...noticeData,
                        authorName,
                    };
                })
            );
            
            const filtered = noticeList.filter((notice) =>
                notice.title.toLowerCase().includes(searchTxt.toLowerCase()) ||
                notice.content.toLowerCase().includes(searchTxt.toLowerCase())
            );
            const paginatedData = filtered.slice(0, currentPage * ITEMS_PER_PAGE);
            
            setDatas(paginatedData);
            setHasMore(paginatedData.length === currentPage * ITEMS_PER_PAGE);
            setLoading(false);
        } catch (error) {
            console.error("공지사항 데이터를 가져오는 중 오류 발생:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNotices(); // 데이터 가져오기
    }, [currentPage]);

    // 검색어 입력 핸들러
    const onChangeSearchTxt = (e) => {
        setSearchTxt(e.target.value);
    };

    // 검색 기능
    const onSearch = () => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollTop = 0;
        }
        setCurrentPage(1);
        setDatas([]); // 검색할 때 기존 테이블 데이터를 초기화
        setHasMore(true); // 새로운 검색 시 더 로드할 데이터가 있음을 true로 설정
        fetchNotices();
    };

    const handleSearchAll = async () => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollTop = 0;
        }
        setSearchTxt("");
        setCurrentPage(1);
        setDatas([]); // 검색할 때 기존 테이블 데이터를 초기화
        setHasMore(true); // 새로운 검색 시 더 로드할 데이터가 있음을 true로 설정
        onLoadAll();
    };

    // 전체 보기 기능
    const onLoadAll = async () => {
        try {
            setLoading(true);
            const noticeQuery = query(collection(db, "notice"), orderBy("createdAt", "desc")); // 날짜순 정렬 쿼리 사용
            const querySnapshot = await getDocs(noticeQuery);
            const noticeList = await Promise.all(
                querySnapshot.docs.map(async (document) => {
                    const noticeData = document.data();
                    let authorName = "";

                    if (noticeData.authorType === "admin") {
                        authorName = "관리자";
                    } else if (noticeData.authorType === "company") {
                        const companyDocRef = doc(db, "company", noticeData.authorId);
                        const companyDocSnap = await getDoc(companyDocRef);
                        authorName = companyDocSnap.exists() ? companyDocSnap.data().companyName : "Unknown Company";
                    } else if (noticeData.authorType === "fc") {
                        const fcDocRef = doc(db, "fc", noticeData.authorId);
                        const fcDocSnap = await getDoc(fcDocRef);
                        authorName = fcDocSnap.exists() ? fcDocSnap.data().fcName : "Unknown FC";
                    }

                    return {
                        id: document.id,
                        ...noticeData,
                        authorName,
                    };
                })
            );

            const paginatedData = noticeList.slice(0, currentPage * ITEMS_PER_PAGE);
            setDatas(paginatedData);
            setHasMore(paginatedData.length === currentPage * ITEMS_PER_PAGE);
            setLoading(false);
        } catch (error) {
            console.error("공지사항 데이터를 가져오는 중 오류 발생:", error);
            setLoading(false);
        }
    };

    const handleScroll = () => {
        if (tableContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 50 && hasMore && !loading) {
                setCurrentPage((prevPage) => prevPage + 1);
            }
        }
    };

    useEffect(() => {
        const currentTableContainer = tableContainerRef.current;
        if (currentTableContainer) {
            currentTableContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (currentTableContainer) {
                currentTableContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [hasMore, loading]);
useEffect(()=>{
    if (!open) {
        handleSearchAll()
    }
},[open])
    return (
        <div className='notice-content'>
            <LoadingDialog open={loading} />
            <CreateNoticeDialog open={open} onClose={onClose}  />
            <div className="notice-create-row">
                <Button variant='contained' onClick={onOpen}>
                    공지사항 작성
                </Button>
            </div>
            <div className="notice-search-row">
                <DefaultTxtfield
                    width={"700px"}
                    placeholder='제목 , 내용으로 검색하세요.'
                    value={searchTxt}
                    onChange={onChangeSearchTxt}
                />
                <Button variant='contained' onClick={onSearch}>
                    검색
                </Button>
                <Button
                    variant='outlined'
                    sx={{ backgroundColor: "white" }}
                    onClick={handleSearchAll}
                >
                    전체 보기
                </Button>
            </div>
            <NoticeTable datas={datas} loadAllDatas={handleSearchAll} tableContainerRef={tableContainerRef} />
        </div>
    );
}

export default Notice;
