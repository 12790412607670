import { styled, TableCell, tableCellClasses, TableRow } from "@mui/material";
import { green2, green3, grey1, grey2, purple1 } from "./colors";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      color: purple1,
      fontSize: 14,
      fontWeight:600,
         minWidth:"70px"
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    '&:hover': {
      backgroundColor: grey2,
    },
  }));
  