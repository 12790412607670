import React, { useEffect, useRef, useState } from 'react';
import FCTable from './FCTable';
import { Button } from '@mui/material';
import './FCManage.css'
import CreateCompanyDialog from '../Component/Dialog/CreateCompanyDialog';
import { db } from '../Firebase/Firebase';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import LoadingDialog from '../Component/Dialog/LoadingDialog';
import DefaultTxtfield from '../Component/Txtfield/DefaultTxtfield';
import CreateFCDialog from '../Component/Dialog/CreateFCDialog';
import { ITEMS_PER_PAGE } from '../const/value';
function FCManage(props) {
    const [openCreate, setOpenCreate] = useState(false);
    const [datas,setDatas]=useState([])
    const [loading,setLoading]=useState(false)
    const [searchTxt,setSearchTxt]=useState("")
    const [hasMore, setHasMore] = useState(true); // 데이터가 더 있는지 확인
    const [currentPage, setCurrentPage] = useState(1);
// const loggedAdmin= localStorage.getItem("loginType")==="admin"
const tableContainerRef = useRef(null);
    const loginType = localStorage.getItem("loginType");
    const dataFilterId= localStorage.getItem("dataFilterId");
    const onClose = async () => {
        setOpenCreate(false);
        // getDatas(); // 계정 생성 후 다시 데이터를 가져옵니다.
    };
    const onOpen = async () => {
        setOpenCreate(true);
    };
    const onChangeSearchTxt=(e)=>{
        setSearchTxt(e.target.value)
    }
        // 첫 화면에서 모든 데이터를 가져오는 함수
        const loadAllDatas = async () => {
            try {
                setLoading(true);
                
                // 'fc' 컬렉션에서 FC 데이터를 가져옴
                const companyQuery = collection(db, 'fc');
                const q = query(companyQuery, orderBy('createdAt', 'desc'));
                const querySnapshot = await getDocs(q);
                
                // FC 데이터를 companyId로 매핑하여 managerName을 추가
                const companyList = await Promise.all(
                    querySnapshot.docs.map(async (doc) => {
                        const fcData = doc.data();
                        let companyInfo = ""; // 기본값 빈 문자열
                        
                        // companyId가 있으면 해당 대리점 정보 가져오기
                        if (fcData.companyId) {
                            const companyQuerySnapshot = await getDocs(query(collection(db, 'company'), orderBy('createdAt', 'desc')));
                            const companyDoc = companyQuerySnapshot.docs.find((doc) => doc.id === fcData.companyId);
                            if (companyDoc) {
                                companyInfo = `${companyDoc.data().companyName} ${companyDoc.data().managerName}` || ""; // managerName을 companyInfo에 추가
                            }
                        }
        
                        return {
                            docId: doc.id,
                            ...fcData,
                            companyInfo // managerName을 companyInfo 필드로 저장
                        };
                    })
                );
                
                // company로 로그인한 경우, dataFilterId와 일치하는 fc만 필터링
                const filteredCompanyList = loginType === "company"
                    ? companyList.filter(fc => fc.companyId === dataFilterId)
                    : companyList;
                    const paginatedData= filteredCompanyList.slice(0, currentPage * ITEMS_PER_PAGE);
                // state에 데이터 저장
                setDatas(paginatedData);
                setHasMore(paginatedData.length === currentPage * ITEMS_PER_PAGE);
                setLoading(false);
            } catch (error) {
                console.error("데이터를 가져오는 중 오류 발생: ", error);
                setLoading(false);
            }
        };
        
    
        const getDatas = async () => {
            try {
                setLoading(true);
                
                let companyQuery = collection(db, 'fc');
                let q = query(companyQuery, orderBy('createdAt', 'desc'));
        
                // Firestore에서 모든 FC 데이터를 가져옵니다.
                const querySnapshot = await getDocs(q);
                let companyList = querySnapshot.docs.map((doc) => ({
                    docId: doc.id,
                    ...doc.data(),
                }));
        
                // companyId로 company 컬렉션에서 managerName을 가져와 추가
                const updatedCompanyList = await Promise.all(
                    companyList.map(async (fc) => {
                        if (fc.companyId) {
                            const companyQuerySnapshot = await getDocs(query(collection(db, 'company'), orderBy('createdAt', 'desc')));
                            const companyDoc = companyQuerySnapshot.docs.find((doc) => doc.id === fc.companyId);
                            if (companyDoc) {
                                return {
                                    ...fc,
                                    companyInfo: `${companyDoc.data().companyName} ${companyDoc.data().managerName}` || "", // managerName을 companyInfo에 추가
                                };
                            }
                        }
                        return fc; // companyId가 없으면 그대로 반환
                    })
                );
        
                // 검색 텍스트가 있을 경우 필터링
                const filtered = updatedCompanyList.filter(c => c.fcName.includes(searchTxt) || c.fcId.includes(searchTxt));
        
                // company로 로그인한 경우, dataFilterId와 일치하는 fc만 필터링
                const finalFilteredList = loginType === "company"
                    ? filtered.filter(fc => fc.companyId === dataFilterId)
                    : filtered;
                    const paginatedData= finalFilteredList.slice(0, currentPage * ITEMS_PER_PAGE);
                setDatas(paginatedData);
                setHasMore(paginatedData.length === currentPage * ITEMS_PER_PAGE);
                setLoading(false);
            } catch (error) {
                console.error("데이터를 가져오는 중 오류 발생: ", error);
                setLoading(false);
            }
        };
        
        const handleSearchAll=async()=>{
            if (tableContainerRef.current) {
                tableContainerRef.current.scrollTop = 0
        
            }
         
            setSearchTxt("")
            setCurrentPage(1);
            setDatas([]); // 검색할 때 기존 테이블 데이터를 초기화
            setHasMore(true); // 새로운 검색 시 더 로드할 데이터가 있음을 true로 설정  
            loadAllDatas()
        }
        // 컴포넌트가 처음 렌더링될 때 모든 데이터를 불러옴
        useEffect(() => {
            getDatas();
        }, [currentPage]);
        const handleScroll = () => {
            if (tableContainerRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
                if (scrollTop + clientHeight >= scrollHeight - 50 && hasMore && !loading) {
                    // 페이지 번호 증가
                    setCurrentPage((prevPage) => prevPage + 1);
                }
            }
        };
        useEffect(() => {
            const currentTableContainer = tableContainerRef.current;
            if (currentTableContainer) {
                currentTableContainer.addEventListener('scroll', handleScroll);
            }
        
            return () => {
                if (currentTableContainer) {
                    currentTableContainer.removeEventListener('scroll', handleScroll);
                }
            };
        }, [hasMore, loading]);
    return (
        <div className='fc-content'>
            <LoadingDialog open={loading}/>
            {/* 대리점 등록 창 */}
            <CreateFCDialog open={openCreate} onClose={onClose} loadAllDatas={loadAllDatas}/>
            <div className="create-row">
                <Button  variant='contained' onClick={onOpen}>
                    FC 등록
                </Button>
            </div>
            <div className="fc-create-row"></div>

            <div className="fc-search-row">
                <DefaultTxtfield width={"700px"} placeholder='FC명 , 아이디로 검색하세요.' value={searchTxt} onChange={onChangeSearchTxt}/>
                <Button variant='contained' onClick={()=>{
                    getDatas()
                }}>검색</Button>
                <Button variant='outlined'
                sx={{
                    backgroundColor:"white"
                }}
                onClick={handleSearchAll}
                >전체 보기</Button>
             </div>
            <FCTable 
            tableContainerRef={tableContainerRef}
            datas={datas} loadAllDatas={loadAllDatas}/>
        </div>
    );
}

export default FCManage;