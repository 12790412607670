import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../Firebase/Firebase';

function EditViceDialog({ open, onClose, row,useDelete=true }) {
    const [docId, setDocId] = useState('');
    const [viceId, setViceId] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    
    useEffect(() => {
        if (row) {
            setDocId(row.docId);
            setViceId(row.id);
            setName(row.name);
            setPassword(row.password);
        }
    }, [row]);

    const handleEdit = async () => {
        if (!name || !password) {
            alert('이름과 비밀번호를 입력하세요.');
            return;
        }

        try {
            const viceRef = doc(db, 'vice', docId);
            await updateDoc(viceRef, {
                name: name,
                password: password,
            });
            alert('부대표 정보가 성공적으로 수정되었습니다!');
            onClose();
        } catch (error) {
            console.error('Error updating document:', error);
            alert('부대표 정보 수정에 실패했습니다.');
        }
    };

    const handleDelete = async () => {
        const confirmDelete = window.confirm('정말로 이 부대표를 삭제하시겠습니까?');
        if (!confirmDelete) return;

        try {
            const viceRef = doc(db, 'vice', docId);
            await deleteDoc(viceRef);
            alert('부대표가 성공적으로 삭제되었습니다.');
            onClose();
        } catch (error) {
            console.error('Error deleting document:', error);
            alert('부대표 삭제에 실패했습니다.');
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>부대표 수정</DialogTitle>
            <DialogContent>
                <Typography sx={{
                    height: "50px",
                    textIndent: "10px"
                }}>아이디 : {viceId}</Typography>
                <TextField
                    label="이름"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    margin="dense"
                />
                <TextField
                    label="비밀번호"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    margin="dense"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>취소</Button>
                <Button onClick={handleEdit} color="primary">수정</Button>
                {useDelete&&<Button onClick={handleDelete} color="error">삭제</Button>}
            </DialogActions>
        </Dialog>
    );
}

export default EditViceDialog;
