import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

function DefaultSelect({ label, options, value, onChange,width=null }) {
    return (
        <FormControl fullWidth>
            <InputLabel id="select-label">{label}</InputLabel>
            <Select
                size='small'
                labelId="select-label"
                value={value}
                onChange={onChange}
                label={label}
                sx={{
                    width:width,
                
                }}
            >
                {options&&options.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default DefaultSelect;
