import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../Firebase/Firebase';

const EditAdminDialog = ({ open, handleClose, loginType, dataFilterId }) => {
  const [newId, setNewId] = useState('');
  const [newPw, setNewPw] = useState('');

  // Firebase에서 admin 컬렉션의 account 문서 수정
  const handleSave = async () => {
    if (loginType === 'admin') {
      try {
        const docRef = doc(db, 'admin', 'account');
        await updateDoc(docRef, {
          id: newId,
          pw: newPw
        });
        alert('관리자 정보가 수정되었습니다.');
        handleClose();
      } catch (error) {
        console.error('관리자 정보를 수정하는 중 오류 발생:', error);
        alert('관리자 정보를 수정하는 중 오류가 발생했습니다.');
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>관리자 정보 수정</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="아이디"
          type="text"
          fullWidth
          value={newId}
          onChange={(e) => setNewId(e.target.value)}
        />
        <TextField
          margin="dense"
          label="비밀번호"
          type="password"
          fullWidth
          value={newPw}
          onChange={(e) => setNewPw(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={handleClose}>닫기</Button>
        <Button variant='contained' onClick={handleSave}>수정</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAdminDialog;
