import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DefaultTxtfield from '../Txtfield/DefaultTxtfield';
import DefaultSelect from '../Select/DefaultSelect';
import DefaultTxtArea from '../TxtArea/DefaultTxtArea';
import DefaultAutoComplete from '../AutoComplete/DefaultAutoComplete';
import { addDoc, collection, doc, updateDoc, getDocs, deleteDoc } from "firebase/firestore";
import { db } from '../../Firebase/Firebase'; // Firebase 설정 파일에서 db import

function EditFCDialog({ open, onClose, row,useDelete=true }) {
    const [selected, setSelected] = useState("2");
    const [fcId, setFcId] = useState("");
    const [fcName, setFcName] = useState("");
    const [fcContact, setFcContact] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [memo, setMemo] = useState("");
    const [companyName,setCompanyName]=useState("")
    const [companyId, setCompanyId] = useState(""); // 대리점 ID 저장
    const [companyOptions, setCompanyOptions] = useState([]); // 대리점 리스트
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [docId,setDocId]=useState("")
    const loginType = localStorage.getItem("loginType");
    const dataFilterId= localStorage.getItem("dataFilterId");
    // 각 필드의 오류 상태 관리
    const [errors, setErrors] = useState({
        mobile: '',
        password: '',
        passwordConfirm: ''
    });

    const DialogContentSx = {
        display: "flex",
        flexDirection: "column",
        gap: "20px"
    };

    useEffect(() => {
        if (row) {
            setDocId(row.docId)
            setFcId(row.fcId)
            setFcName(row.fcName);
            setEmail(row.email);
            setFcContact(row.fcContact);
            setMemo(row.memo);
            setMobile(row.mobile);
            setPassword(row.password);
            setCompanyId(row.companyId || ""); // 기존 선택된 대리점
        }
    }, [row]);

    useEffect(() => {
        if (loginType === "company") {
            // 대리점으로 로그인 했으면 company컬렉션에서 dataFilterId를 docId로 써서 `${company.companyName} ${company.managerName}`을 selectedDealership.label에 넣고, selectedDealership.id에 dataFilterId를 넣자,
            const fetchCompanyData = async () => {
                try {
                    const docRef = collection(db, "company");
                    const querySnapshot = await getDocs(docRef);
                    const selectedDoc = querySnapshot.docs.find(doc => doc.id === dataFilterId);
    
                    if (selectedDoc) {
                        const companyData = selectedDoc.data();
                        const selectedDealership = {
                            id: dataFilterId,
                            label: `${companyData.companyName} ${companyData.managerName}`,
                        };
                        setCompanyId(selectedDealership.id);
                        setCompanyName(selectedDealership.label)
                    }
                } catch (error) {
                    console.error("대리점 정보를 가져오는 중 오류 발생:", error);
                }
            };
    
            fetchCompanyData(); // 대리점 정보 가져오기 함수 실행
        } else {
              // 대리점 리스트 가져오기
        const fetchCompanies = async () => {
            setLoading(true);
            try {
                const querySnapshot = await getDocs(collection(db, 'company'));
                const options = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    label: `${doc.data().companyName} ${doc.data().managerName}`, // 대리점 이름
                }));
                setCompanyOptions(options); // 대리점 리스트 설정
            } catch (error) {
                console.error("대리점 리스트를 가져오는 중 오류 발생:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchCompanies();
        }
      
    }, []);

    const handleFcNameChange = (e) => setFcName(e.target.value);
    const handleFcContactChange = (e) => {
        const value = e.target.value;
        const regex = /^[0-9-]*$/; // 숫자와 '-'만 입력 가능
        if (regex.test(value)) {
           setFcContact(value) 
        }
        };
    const handleMobileChange = (e) => {
        const value = e.target.value;
        const regex = /^[0-9-]*$/; // 숫자와 '-'만 입력 가능
        if (regex.test(value)) {
            setMobile(value);
        }
    };
    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
            setEmailError("올바른 이메일 형식이 아닙니다.");
        } else {
            setEmailError("");
        }
    };
    const handlePasswordChange = (e) => setPassword(e.target.value);
    const handlePasswordConfirmChange = (e) => setPasswordConfirm(e.target.value);
    const handleMemoChange = (e) => setMemo(e.target.value);
    const handleCompanySelect = (event, newValue) => {
        setCompanyId(newValue?.id || ""); // 선택된 대리점의 ID 설정
    };

    const validate = () => {
        const newErrors = {};
        if (!mobile) {
            newErrors.mobile = "휴대전화는 필수 입력 항목입니다.";
        }
        if (!password) {
            newErrors.password = "비밀번호는 필수 입력 항목입니다.";
        }
        if (!passwordConfirm) {
            newErrors.passwordConfirm = "비밀번호 확인은 필수 입력 항목입니다.";
        }
        if (password && passwordConfirm && password !== passwordConfirm) {
            newErrors.passwordConfirm = "비밀번호가 일치하지 않습니다.";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validate()) {
            return; // 유효성 검사 실패 시, 등록 중단
        }
        try {
            // Firebase Firestore에서 수정할 문서의 참조를 가져옴
            const docRef = doc(db, "fc", row.docId); // row 객체 안에 docId가 있어야 함

            // 수정할 데이터 객체
            const updatedData = {
                fcName,
                fcContact,
                mobile,
                email,
                password,
                memo,
                companyId, // 선택된 대리점 ID 추가
                grade:selected
            };

            // Firestore에서 문서 업데이트
            await updateDoc(docRef, updatedData);

            // 성공적으로 수정된 경우
            alert("FC 수정이 성공적으로 완료되었습니다.");
            onClose(); // 다이얼로그 닫기

            // 입력값 초기화
            setFcName("");
            setFcContact("");
            setMobile("");
            setEmail("");
            setPassword("");
            setPasswordConfirm("");
            setMemo("");
            setErrors({}); // 오류 상태 초기화
        } catch (error) {
            // 수정 중 오류 발생 시
            alert(`FC 수정 중 오류가 발생했습니다: ${error.message}`);
            console.error("Error updating document: ", error);
        }
    };
    const accountOptions = [
        { value: "1", label: "부대표" },
        { value: "2", label: "일반" },
    ];
    const onChange = (e) => {
        const sel = e.target.value;
        setSelected(sel);
    };
    const handleDelete = async () => {
        const confirmation = window.confirm("정말로 이 FC를 삭제하시겠습니까?");
        if (!confirmation) return;

        try {
            await deleteDoc(doc(db, "fc", docId));
            alert("FC가 성공적으로 삭제되었습니다.");
            onClose();
        } catch (error) {
            console.error("FC 삭제 중 오류 발생:", error);
        }
    };
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md">
            <DialogTitle>FC 수정</DialogTitle>
            <DialogContent sx={DialogContentSx}>
            <Typography>아이디: {fcId}</Typography> {/* 생성된 FC ID 표시 */}
                {/* 대리점 선택 Autocomplete 추가 */}
               {loginType==="company"?<Typography>{companyName}</Typography>:  <DefaultAutoComplete
                    idOptions={companyOptions}
                    loading={loading}
                    handleOptionChange={handleCompanySelect}
                    placeholder="대리점 검색(ex:부산대리점 김주현)"
                />}
                <DefaultTxtfield
                    width={"500px"}
                    placeholder='FC명'
                    value={fcName}
                    onChange={handleFcNameChange}
                />
                <DefaultTxtfield
                    width={"500px"}
                    placeholder='FC연락처'
                    value={fcContact}
                    onChange={handleFcContactChange}
                />
                <DefaultTxtfield
                    width={"500px"}
                    placeholder='휴대전화'
                    value={mobile}
                    onChange={handleMobileChange}
                    error={!!errors.mobile}
                    helperText={errors.mobile}
                />
                  {/* <DefaultSelect options={accountOptions} value={selected} onChange={onChange} /> */}
                <DefaultTxtfield
                    width={"500px"}
                    placeholder='이메일'
                    value={email}
                    onChange={handleEmailChange}
                />
                <DefaultTxtfield
                    width={"500px"}
                    placeholder='비밀번호'
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    error={!!errors.password}
                    helperText={errors.password}
                />
                <DefaultTxtfield
                    width={"500px"}
                    placeholder='비밀번호 확인'
                    type="password"
                    value={passwordConfirm}
                    onChange={handlePasswordConfirmChange}
                    error={!!errors.passwordConfirm}
                    helperText={errors.passwordConfirm}
                />
                <DefaultTxtArea
                    placeholder='메모'
                    value={memo}
                    onChange={handleMemoChange}
                />
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={onClose}>닫기</Button>
                <Button variant='contained' onClick={handleSubmit}>수정</Button>
               {useDelete&& <Button variant='outlined' color='error' onClick={handleDelete}>삭제</Button>}
            </DialogActions>
        </Dialog>
    );
}

export default EditFCDialog;
