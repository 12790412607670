import { Button } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import DefaultTxtfield from '../Component/Txtfield/DefaultTxtfield';
import './Vice.css';
import ViceTable from './ViceTable';
import CreateViceDialog from '../Component/Dialog/CreateViceDialog';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../Firebase/Firebase';
import { ITEMS_PER_PAGE } from '../const/value';
import LoadingDialog from '../Component/Dialog/LoadingDialog';

function Vice(props) {
    const [open, setOpen] = useState(false);
    const [searchTxt, setSearchTxt] = useState("");
    const [datas, setDatas] = useState([]); // 데이터를 저장할 상태 추가
    const tableContainerRef=useRef(null)
    const [loading,setLoading]=useState(false)
    const [hasMore, setHasMore] = useState(true); // 데이터가 더 있는지 확인
    const [currentPage, setCurrentPage] = useState(1);
    const onChangeSearchTxt = (e) => {
        setSearchTxt(e.target.value);
    };

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        handleSearchAll()
    };
   const loadAll=async()=>{
        try {
            setLoading(true)
            const viceQuery = query(
                collection(db, "vice"),
                orderBy("createdAt", "desc") // createdAt 기준으로 내림차순 정렬
            );
            const querySnapshot = await getDocs(viceQuery);
            const dataList = querySnapshot.docs.map((doc) => ({
                docId: doc.id,
                ...doc.data()
            }));    
             const paginatedData= dataList.slice(0, currentPage * ITEMS_PER_PAGE);
        
            setDatas(paginatedData);   
             setHasMore(paginatedData.length === currentPage * ITEMS_PER_PAGE);
            setLoading(false)
        } catch (error) {
            console.error("Error fetching vice data:", error);
        }
    }
    const getDatas = async () => {
        try {setLoading(true);
            const viceQuery = query(
                collection(db, "vice"),
                orderBy("createdAt", "desc") // createdAt 기준으로 오름차순 정렬
            );
            const querySnapshot = await getDocs(viceQuery);
            const dataList = querySnapshot.docs.map((doc) => ({
                docId: doc.id,       // 문서 ID도 가져올 수 있음
                ...doc.data()     // 문서의 데이터 필드
            }));
            const filtered=dataList.filter(c=>c.id.includes(searchTxt)||c.name.includes(searchTxt))
            const paginatedData= filtered.slice(0, currentPage * ITEMS_PER_PAGE);
      
            setDatas(paginatedData); // 상태 업데이트
            setHasMore(paginatedData.length === currentPage * ITEMS_PER_PAGE);
                  setLoading(false);
        } catch (error) {
            console.error("Error fetching vice data:", error);
        }
    };

    const onSearch = () => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollTop = 0
    
        }
     
        setSearchTxt("")
        setCurrentPage(1);
        setDatas([]); // 검색할 때 기존 테이블 데이터를 초기화
        setHasMore(true); // 새로운 검색 시 더 로드할 데이터가 있음을 true로 설정  
        getDatas()
    };
    const handleSearchAll = async() => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollTop = 0
    
        }
     
        setSearchTxt("")
        setCurrentPage(1);
        setDatas([]); // 검색할 때 기존 테이블 데이터를 초기화
        setHasMore(true); // 새로운 검색 시 더 로드할 데이터가 있음을 true로 설정  
        loadAll()
    };
 
    useEffect(() => {
        getDatas();
    }, [currentPage]);
    const handleScroll = () => {
        if (tableContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 50 && hasMore && !loading) {
                // 페이지 번호 증가
                setCurrentPage((prevPage) => prevPage + 1);
            }
        }
    };
    useEffect(() => {
        const currentTableContainer = tableContainerRef.current;
        if (currentTableContainer) {
            currentTableContainer.addEventListener('scroll', handleScroll);
        }
    
        return () => {
            if (currentTableContainer) {
                currentTableContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [hasMore, loading]);
    return (
        <div className='vice-content'>
            <LoadingDialog open={loading}/>
            <CreateViceDialog open={open} onClose={onClose} />
            <div className="notice-create-row">
                <Button variant='contained' onClick={onOpen}>
                    부대표 생성
                </Button>
            </div>
            <div className="notice-search-row">
                <DefaultTxtfield
                    width={"700px"}
                    placeholder='아이디 , 이름으로 검색하세요.'
                    value={searchTxt}
                    onChange={onChangeSearchTxt}
                />
                <Button variant='contained' onClick={onSearch}>
                    검색
                </Button>
                <Button
                    variant='outlined'
                    sx={{ backgroundColor: "white" }}
                    onClick={handleSearchAll}
                >
                    전체 보기
                </Button>
            </div>
            <ViceTable datas={datas} loadAllDatas={handleSearchAll} tableContainerRef={tableContainerRef}/> {/* 데이터를 ViceTable 컴포넌트에 전달 */}
        </div>
    );
}

export default Vice;
