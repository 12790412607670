import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, CssBaseline, Drawer, List, ListItem, ListItemIcon, ListItemText, Box, IconButton, Divider, Avatar } from '@mui/material';
import { Menu, ChevronLeft, LogoutOutlined } from '@mui/icons-material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { db } from '../Firebase/Firebase'; // Firebase 설정 파일에서 db import
import { collection, doc, getDoc } from 'firebase/firestore';
import { getSidebarName } from '../const/func';
import { black1, green1, green2, grey1, grey2, grey3, grey4 } from '../const/colors';
import { FaBuilding, FaPen, FaUserTie } from 'react-icons/fa';
import { MdAnnouncement } from 'react-icons/md';
import { IoIosPerson } from 'react-icons/io';
import EditAdminDialog from '../Component/Dialog/EditAdminDialog';
import EditFCDialog from '../Component/Dialog/EditFCDialog'; // EditFCDialog 추가
import EditCompanyDialog from '../Component/Dialog/EditCompanyDialog'; // EditCompanyDialog 추가
import { RiUserStarLine } from 'react-icons/ri';
import EditViceDialog from '../Component/Dialog/EditViceDialog';

const drawerWidth = 240;

const DashboardLayout = () => {
  const [open, setOpen] = useState(true); // 사이드바 상태 관리
  const [loginName, setLoginName] = useState(""); // 로그인 이름 상태
  const [dialogOpen, setDialogOpen] = useState(false); // 다이얼로그 상태 관리
  const [fcDialogOpen, setFcDialogOpen] = useState(false); // FC 다이얼로그 상태 관리
  const [companyDialogOpen, setCompanyDialogOpen] = useState(false); // Company 다이얼로그 상태 관리
  const [viceDialogOpen, setViceDialogOpen] = useState(false); // Company 다이얼로그 상태 관리
  const [fcData, setFcData] = useState(null); // FC 데이터를 저장할 상태
  const [viceData, setViceData] = useState(null); // FC 데이터를 저장할 상태
  const [companyData, setCompanyData] = useState(null); // Company 데이터를 저장할 상태
  const navigate = useNavigate();
  const location = useLocation(); // 현재 경로 가져오기
  const loginType = localStorage.getItem("loginType");
  const dataFilterId = localStorage.getItem("dataFilterId");

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    const res = window.confirm("로그아웃 하시겠습니까?");
    if (res) {
      localStorage.setItem('isLoggedIn', 'false');
      localStorage.removeItem('loginType');
      localStorage.removeItem('dataFilterId');
      navigate("/");
    }
  };

  const handleEditClick = async () => {
    if (loginType === 'admin') {
      setDialogOpen(true); // 관리자 다이얼로그 열기
    } else if (loginType === 'fc') {
      try {
        const docRef = doc(collection(db, 'fc'), dataFilterId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setFcData({ docId: docSnap.id, ...docSnap.data() }); // FC 데이터를 상태에 저장
          setFcDialogOpen(true); // FC 다이얼로그 열기
        } else {
          alert('FC 정보를 찾을 수 없습니다.');
        }
      } catch (error) {
        console.error('FC 정보를 가져오는 중 오류 발생:', error);
      }
    } else if (loginType === 'company') {
      try {
        const docRef = doc(collection(db, 'company'), dataFilterId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setCompanyData({ docId: docSnap.id, ...docSnap.data() }); // Company 데이터를 상태에 저장
          setCompanyDialogOpen(true); // Company 다이얼로그 열기
        } else {
          alert('대리점 정보를 찾을 수 없습니다.');
        }
      } catch (error) {
        console.error('대리점 정보를 가져오는 중 오류 발생:', error);
      }
    }else if (loginType === 'vice') {
      try {
        const docRef = doc(collection(db, 'vice'), dataFilterId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setViceData({ docId: docSnap.id, ...docSnap.data() }); // Company 데이터를 상태에 저장
          setViceDialogOpen(true); // Company 다이얼로그 열기
        } else {
          alert('대리점 정보를 찾을 수 없습니다.');
        }
      } catch (error) {
        console.error('대리점 정보를 가져오는 중 오류 발생:', error);
      }
    }else{

    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false); // 관리자 다이얼로그 닫기
  };

  const handleFcDialogClose = () => {
    setFcDialogOpen(false); // FC 다이얼로그 닫기
    getLoginName(loginType);
  };

  const handleCompanyDialogClose = () => {
    setCompanyDialogOpen(false); // Company 다이얼로그 닫기
    getLoginName(loginType);
  };
  const handleViceDialogClose = () => {
    setViceDialogOpen(false); // Company 다이얼로그 닫기
    getLoginName(loginType);
  };

  // Firestore에서 loginType에 따른 이름을 가져오는 함수
  const getLoginName = async (type) => {
    console.log("로그인 타입>>>",type)
    if (type === "admin") {
      setLoginName("관리자");
    } else if (type === "fc") {
      try {
        const docRef = doc(collection(db, "fc"), dataFilterId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setLoginName(docSnap.data().fcName);
        } else {
          setLoginName("FC");
        }
      } catch (error) {
        console.error("FC 정보를 가져오는 중 오류 발생:", error);
        setLoginName("FC");
      }
    } else if (type === "company") {
      try {
        const docRef = doc(collection(db, "company"), dataFilterId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setLoginName(docSnap.data().companyName);
        } else {
          setLoginName("대리점");
        }
      } catch (error) {
        console.error("대리점 정보를 가져오는 중 오류 발생:", error);
        setLoginName("대리점");
      }
    }
    else if (type === "vice") {
      try {
        const docRef = doc(collection(db, "vice"), dataFilterId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setLoginName(docSnap.data().name);
        } else {
          setLoginName("부대표");
        }
      } catch (error) {
        console.error("대리점 정보를 가져오는 중 오류 발생:", error);
        setLoginName("대리점");
      }
    }else{

    }
  };

  useEffect(() => {
    getLoginName(loginType);
  }, [loginType]);

  const isActive = (path) => location.pathname === path;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar 
        position="fixed" 
        variant='outlined'
        sx={{ 
          width: open ? `calc(100% - ${drawerWidth}px)` : `calc(100%)`,
          ml: open ? `${drawerWidth}px` : 0,
          transition: 'width 0.3s ease',
          backgroundColor: grey4,
          borderLeft: "none",
          color: "black",
        }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ 
              border:`1px solid ${green1}`,
              backgroundColor: green2,
              mr: 2, ...(open && { display: 'none' }) }}
          >
            <Menu sx={{ color: green1 }} />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{
            color:black1,
            fontWeight:600
          }}>
            {getSidebarName(location.pathname)}
          </Typography>
          <div style={{ flex: 1 }} />
          <Avatar src=''/>
          <div style={{width:"10px"}} />
          <Typography variant="h7">{loginName}</Typography>
          <div style={{ width: "60px" }} />
          <IconButton sx={{ 
             border:`1px solid ${green1}`,
            backgroundColor: green2,
            color: green1 }} onClick={handleLogout}>
            <LogoutOutlined color={green1} />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            color:grey3,
            boxSizing: 'border-box',
            transition: 'width 0.3s ease',
          },
        }}
      >
        <Toolbar>
          <Typography variant='h6' color={green1}>INCO DB </Typography>
          {/* <img src="./logo.png" style={{
            width:"100px"
          }}/> */}
          <div style={{
            flex:1
          }}/>
          <IconButton onClick={handleDrawerToggle}>
            <ChevronLeft sx={{color:green1}}/>
          </IconButton>
        </Toolbar>
        <Divider/>
        <Toolbar
        sx={{
          display:"flex",
          alignItems:"center",
          gap:"16px",
          marginTop:"10px",
          marginBottom:"10px"
        }}
        ><Avatar src=''/>
      <Typography 
  variant="h7" 
  fontWeight={600}
  sx={{ 
    color:black1,
    whiteSpace: 'nowrap', 
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    maxWidth: '150px' // 적절한 최대 너비 설정
  }}
>
  {loginName}
</Typography>
        <IconButton onClick={handleEditClick}><FaPen/></IconButton> {/* Edit Button */}
        </Toolbar>
        <Divider/>
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <ListItem 
              sx={{
                backgroundColor:isActive("/member") ?grey1:null
              }}
              button onClick={() => { navigate("/member") }}>
              <ListItemIcon sx={{ color: isActive("/member") ? green1 : 'inherit' }}>
                <IoIosPerson size={22} />
              </ListItemIcon>
              <ListItemText primary="고객 관리" sx={{
                 cursor:"pointer",
                color: isActive("/member") ? green1 : grey3,
                fontWeight: isActive("/member") ? 700 : 400,
              }}/>
            </ListItem>
           {loginType==="admin"&& <ListItem 
              sx={{
                backgroundColor:isActive("/vice") ?grey1:null
              }}
              button onClick={() => { navigate("/vice") }}>
              <ListItemIcon sx={{ color: isActive("/vice") ? green1 : 'inherit' }}>
                <RiUserStarLine size={22} />
              </ListItemIcon>
              <ListItemText primary="부대표 관리" sx={{
                 cursor:"pointer",
                color: isActive("/vice") ? green1 : grey3,
                fontWeight: isActive("/vice") ? 700 : 400,
              }}/>
            </ListItem>}
            <ListItem 
               sx={{
                backgroundColor:isActive("/notice") ?grey1:null
              }}
              button onClick={() => { navigate("/notice") }}>
              <ListItemIcon sx={{ color: isActive("/notice") ? green1 : 'inherit' }}>
                <MdAnnouncement size={22} />
              </ListItemIcon>
              <ListItemText primary="공지사항" sx={{
                 cursor:"pointer",
                color: isActive("/notice") ? green1 : grey3,
                fontWeight: isActive("/notice") ? 700 : 400,
              }}/>
            </ListItem>

            {(loginType === "admin" ||loginType === "vice")&& (
              <>
                <ListItem 
                  sx={{
                    backgroundColor:isActive("/company") ?grey1:null
                  }}
                  button onClick={() => { navigate("/company") }}>
                  <ListItemIcon sx={{ color: isActive("/company") ? green1 : 'inherit' }}>
                    <FaBuilding size={22} />
                  </ListItemIcon>
                  <ListItemText primary="대리점 관리" sx={{
                     cursor:"pointer",
                    color: isActive("/company") ? green1 : grey3,
                    fontWeight: isActive("/company") ? 700 : 400,
                  }}/>
                </ListItem>
                <ListItem 
                  sx={{
                    backgroundColor:isActive("/fc") ?grey1:null
                  }}
                  button onClick={() => { navigate("/fc") }}>
                  <ListItemIcon sx={{ color: isActive("/fc") ? green1 : 'inherit' }}>
                    <FaUserTie size={22} />
                  </ListItemIcon>
                  <ListItemText primary="FC 관리" sx={{
                    cursor:"pointer",
                    color: isActive("/fc") ? green1 : grey3,
                    fontWeight: isActive("/fc") ? 700 : 400,
                  }}/>
                </ListItem>
              </>
            )}

            {loginType === "company" && (
              <ListItem 
                sx={{
                  backgroundColor:isActive("/fc") ?grey1:null
                }}
                button onClick={() => { navigate("/fc") }}>
                <ListItemIcon sx={{ color: isActive("/fc") ? green1 : 'inherit' }}>
                  <FaUserTie size={22} />
                </ListItemIcon>
                <ListItemText primary="FC 관리" sx={{
                  cursor:"pointer",
                  color: isActive("/fc") ? green1 : grey3,
                  fontWeight: isActive("/fc") ? 700 : 400,
                }}/>
              </ListItem>
            )}
          </List>
        </Box>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: grey4,
          height:"100vh",
          p: 3,
          transition: 'margin-left 0.3s ease',
          ml: open ? 0 : `-${drawerWidth}px`,
          width: open ? `calc(100% - ${drawerWidth}px)` : '100%',
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>

      {/* 관리자 정보 수정 다이얼로그 */}
      <EditAdminDialog 
        open={dialogOpen} 
        handleClose={handleDialogClose} 
        loginType={loginType}
        dataFilterId={dataFilterId}
      />

      {/* FC 정보 수정 다이얼로그 */}
      <EditFCDialog
        open={fcDialogOpen}
        onClose={handleFcDialogClose}
        useDelete={false}
        row={fcData} // 가져온 FC 데이터를 row로 전달
      />

      {/* Company 정보 수정 다이얼로그 */}
      <EditCompanyDialog
        open={companyDialogOpen}
        onClose={handleCompanyDialogClose}
        useDelete={false}
        row={companyData} // 가져온 Company 데이터를 row로 전달
      />
      <EditViceDialog
       open={viceDialogOpen}
       onClose={handleViceDialogClose}
       row={viceData} // 가져온 Vice 데이터를 row로 전달
       useDelete={false}
      />
    </Box>
  );
};

export default DashboardLayout;
