import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { StyledTableCell, StyledTableRow } from '../../const/style';
import { formatDate, getGradeName } from '../../const/func';
import { FaPencilAlt } from "react-icons/fa";
function AccountTable({datas,handleRowClick}) {
    return (
        <TableContainer 
      variant='outlined'
  component={Paper}
  sx={{maxHeight: '700px', overflowY: 'auto' }}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow
      
          >
     
            <StyledTableCell align="left">생성 일자</StyledTableCell>
            <StyledTableCell align="left">계정</StyledTableCell>
            <StyledTableCell align="left">비밀번호</StyledTableCell>
            <StyledTableCell align="left">권한</StyledTableCell>
            <StyledTableCell align="left">수정</StyledTableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {datas.length===0?(<TableRow>
                <TableCell colSpan={7} align="center">데이터 없음(검색조건을 수정하세요)</TableCell>
              </TableRow>):(datas.map((row, idx) => (
            <StyledTableRow key={idx}  
            sx={{
             
              cursor:"pointer"
            }}
        
           >
              <StyledTableCell align="left" >{row.createDt??"-"}</StyledTableCell>
              <StyledTableCell align="left" >{row.id??"-"}</StyledTableCell>
              <StyledTableCell align="left" >{row.pw??"-"}</StyledTableCell>
              <StyledTableCell align="left" >{getGradeName(row.grade)??"-"}</StyledTableCell>
              <StyledTableCell align="left" ><IconButton
           
              onClick={()=>{handleRowClick(row)}}
              ><FaPencilAlt/></IconButton></StyledTableCell>
          

             
          
            </StyledTableRow>
          )))}
        </TableBody>
      </Table>
    </TableContainer>
    );
}

export default AccountTable;