import { Dialog, DialogContent, DialogTitle, DialogActions, TextField, Button, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, addDoc, deleteDoc } from "firebase/firestore"; // deleteDoc added
import { db } from '../../Firebase/Firebase'; // Firebase 설정 파일에서 db import
import LoadingDialog from './LoadingDialog';
import moment from 'moment';

function EditNoticeDialog({ open, onClose ,row}) {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [author, setAuthor] = useState(''); // 작성자 상태
    const [currentDate, setCurrentDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [docId,setDocId]=useState("")
    const loginType = localStorage.getItem("loginType");
    const dataFilterId = localStorage.getItem("dataFilterId");

    useEffect(() => {
        if (row) {
            setDocId(row.id)
            setTitle(row.title);
            setContent(row.content);
            setAuthor(row.authorName);
            setCurrentDate(row.date);
        }
    }, [row]);

    // 제목 입력 핸들러
    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    // 내용 입력 핸들러
    const handleContentChange = (e) => {
        setContent(e.target.value);
    };

    // 공지사항 삭제 핸들러
    const handleDelete = async () => {
        const confirmation = window.confirm("정말로 이 공지사항을 삭제하시겠습니까?");
        if (!confirmation) return;

        try {
            setLoading(true);
            // Firestore에서 문서 삭제
            await deleteDoc(doc(db, "notice", docId)); // row.docId 사용
            setLoading(false);
            alert("공지사항이 성공적으로 삭제되었습니다.");
            onClose(); // 다이얼로그 닫기
        } catch (error) {
            console.error("공지사항 삭제 중 오류 발생:", error);
            setLoading(false);
        }
    };

    // 저장 버튼 클릭 핸들러
    const handleSubmit = async () => {
        if (title.trim() === '' || content.trim() === '') {
            alert("제목과 내용을 모두 입력해 주세요.");
            return;
        }

        try {
            await onSubmit(); // Firestore에 저장하는 함수 호출
            alert("공지사항이 성공적으로 저장되었습니다.");
            setTitle(''); // 폼 초기화
            setContent('');
            onClose(); // 다이얼로그 닫기
        } catch (error) {
            console.error("공지사항 저장 중 오류 발생:", error);
            alert("공지사항 저장 중 오류가 발생했습니다.");
        }
    };

    // Firestore에 공지사항 저장하는 함수
    const onSubmit = async () => {
        try {
            setLoading(true);
            // notice 컬렉션에 데이터 추가
            await addDoc(collection(db, "notice"), {
                title: title,
                content: content,
                date: currentDate,
                createdAt: new Date(), // 추가적으로 생성 시간 저장
                authorId: dataFilterId,
                authorType: loginType,
            });
            setLoading(false);
        } catch (error) {
            console.error("Firestore 저장 중 오류 발생:", error);
            setLoading(false);
            throw error;
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <LoadingDialog open={loading} />
            <DialogTitle>공지사항 수정</DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1" gutterBottom>
                    {currentDate}
                </Typography>

                <Typography variant="subtitle2" gutterBottom>
                    작성자: {author}
                </Typography>

                <TextField
                    margin="dense"
                    label="제목"
                    fullWidth
                    value={title}
                    onChange={handleTitleChange}
                />

                <TextField
                    margin="dense"
                    label="내용"
                    fullWidth
                    multiline
                    rows={20}
                    value={content}
                    onChange={handleContentChange}
                />
            </DialogContent>
            <DialogActions>
                {/* 취소 버튼 */}
                <Button variant="outlined" onClick={onClose}>
                    취소
                </Button>
                {/* 저장 버튼 */}
                <Button variant="contained" onClick={handleSubmit}>
                    저장
                </Button>
                {/* 삭제 버튼 */}
               
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={handleDelete}
                    >
                        삭제
                    </Button>
                
            </DialogActions>
        </Dialog>
    );
}

export default EditNoticeDialog;
