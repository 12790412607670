import React, { useEffect, useRef, useState } from 'react';
import './Account.css';
import LoadingDialog from '../Component/Dialog/LoadingDialog';
import MemberTable from '../Component/Table/MemberTable';
import { Button, TextField, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import CreateMemberDialog from '../Component/Dialog/CreateMemberDialog';
import { db } from '../Firebase/Firebase';
import { addDoc, collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import DateRangePicker from '../Component/Txtfield/DateRangePicker';
import { ITEMS_PER_PAGE, monthCostOptions, receiptOptions, statusOptions } from '../const/value';
import DefaultAutoComplete from '../Component/AutoComplete/DefaultAutoComplete';
import * as XLSX from 'xlsx';  // xlsx 라이브러리 import
import moment from 'moment';
import { getMonthCost } from '../const/func';
import { green2, green3, green4 } from '../const/colors';
function Member(props) {
    const [members, setMembers] = useState([]);
    const [openCreate, setOpenCreate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dateRange, setDateRange] = useState({ startDate: null, endDate: null }); // 날짜 범위 상태 저장
    const [searchText, setSearchText] = useState(""); // 검색 텍스트 상태 저장
    const [monthCost, setMonthCost] = useState(""); // 월보험료 필터링 상태 저장
    const [receiptType, setReceiptType] = useState(""); // 접수유형 필터링 상태 저장
    const [gender, setGender] = useState(""); // 성별 필터링 상태 저장
    const [companyOptions, setCompanyOptions] = useState([]); // 대리점 리스트 저장
    const [fcOptions, setFcOptions] = useState([]); // FC 리스트 저장
    const [selectedCompany, setSelectedCompany] = useState(null); // 선택된 대리점 저장
    const [selectedFc, setSelectedFc] = useState(null); // 선택된 FC 저장
    const [hasMore, setHasMore] = useState(true); // 데이터가 더 있는지 확인
    const [currentPage, setCurrentPage] = useState(1);
// const loggedAdmin= localStorage.getItem("loginType")==="admin"
const tableContainerRef = useRef(null);
const dataFilterId=localStorage.getItem("dataFilterId")
const loginType = localStorage.getItem("loginType");
    const onClose = async () => {
        setOpenCreate(false);
        getDatas();
    };

    const onOpen = async () => {
        setOpenCreate(true);
    };

    // 날짜 범위를 업데이트하는 함수
    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setDateRange((prev) => ({ ...prev, [name]: value }));
    };

    // Firestore에서 대리점과 FC 데이터를 가져오는 함수
    const loadOptions = async () => {
        try {
            setLoading(true);

            // 대리점 리스트 가져오기
            const companySnapshot = await getDocs(collection(db, 'company'));
            const companyList = companySnapshot.docs.map(doc => ({
                id: doc.id,
                label: doc.data().companyName
            }));
            setCompanyOptions(companyList);

            // FC 리스트 가져오기
            const fcSnapshot = await getDocs(collection(db, 'fc'));
            const fcList = fcSnapshot.docs.map(doc => ({
                id: doc.id,
                label: doc.data().fcName
            }));
            setFcOptions(fcList);

            setLoading(false);
        } catch (error) {
            console.error("대리점 및 FC 데이터를 가져오는 중 오류 발생: ", error);
            setLoading(false);
        }
    };

    // 첫 화면에서 모든 데이터를 가져오는 함수
    const loadAllDatas = async () => {
        try {
            
            setLoading(true);
            const memQuery = collection(db, 'member');
            const q = query(memQuery, orderBy('createdAt', 'desc'));
            // if (!loggedAdmin) {
            //     q=query(memQuery, 
            //         where("fcId","==",dataFilterId),
            //         orderBy('createdAt', 'desc'))
            // }
            const querySnapshot = await getDocs(q);
            const memList = querySnapshot.docs.map((doc) => ({
                docId: doc.id,
                ...doc.data(),
            }));
            const fcList=memList.filter(m=>m.fcId===dataFilterId)
            const companyList=memList.filter(m=>m.companyId===dataFilterId)
            console.log("memList>>>", memList);
let filterList=memList
if (loginType==="admin") {
    filterList=memList
}else if(loginType==="vice"){
    filterList=memList
}
else if(loginType==="fc"){
    filterList=fcList
}else {
filterList=companyList
}
const paginatedData= filterList.slice(0, currentPage * ITEMS_PER_PAGE);

            setMembers(paginatedData);
            setHasMore(paginatedData.length === currentPage * ITEMS_PER_PAGE);
            setLoading(false);
        } catch (error) {
            console.error("데이터를 가져오는 중 오류 발생: ", error);
            setLoading(false);
        }
    };

    const getDatas = async () => {
        try {
            setLoading(true);

            let memQuery = collection(db, 'member');
            let q = query(memQuery, orderBy('createdAt', 'desc'));

            // Firestore에서 모든 데이터를 가져옵니다.
            const querySnapshot = await getDocs(q);
            const memList = querySnapshot.docs.map((doc) => ({
                docId: doc.id,
                ...doc.data(),
            }));
            const fcList=memList.filter(m=>m.fcId===dataFilterId)
            const companyList=memList.filter(m=>m.companyId===dataFilterId)
            console.log("memList>>>", memList);
let originList=memList
if (loginType==="admin") {
    originList=memList
}else if (loginType==="vice") {
    originList=memList
}else if(loginType==="fc"){
    originList=fcList
}else {
originList=companyList
}
            // 검색 필터링 로직 (클라이언트 측 필터링)
            const filteredList = originList.filter((member) => {
                const normalizedSearchText = searchText.toLowerCase();

                // 검색 텍스트, 월보험료, 접수유형, 성별이 각각 비어있지 않다면 필터링
                const matchesSearchText = normalizedSearchText
                    ? (member.company?.toLowerCase().includes(normalizedSearchText) ||
                        member.fc?.toLowerCase().includes(normalizedSearchText) ||
                        member.member?.toLowerCase().includes(normalizedSearchText))
                    : true;

                const matchesMonthCost = monthCost ? member.monthCost === monthCost : true;
                const matchesReceiptType = receiptType ? member.receipt === receiptType : true;
                const matchesGender = gender ? member.gender === gender : true;
                const matchesFc=selectedFc?member.fcId===selectedFc.id:true;
                const matchesCompany=selectedCompany?member.companyId===selectedCompany.id:true;
                // 모든 조건이 일치할 때 true를 반환
                return matchesSearchText && matchesMonthCost && matchesReceiptType && matchesGender&&matchesFc&&matchesCompany;
            });
console.log("selectedFc>>>",selectedFc)
            setMembers(filteredList);
            setLoading(false);
        } catch (error) {
            console.error("데이터를 가져오는 중 오류 발생: ", error);
            setLoading(false);
        }
    };
// 스크롤 감지 함수
const handleScroll = () => {
    if (tableContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 50 && hasMore && !loading) {
            // 페이지 번호 증가
            setCurrentPage((prevPage) => prevPage + 1);
        }
    }
};

// 스크롤 이벤트 리스너 추가
useEffect(() => {
    const currentTableContainer = tableContainerRef.current;
    if (currentTableContainer) {
        currentTableContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
        if (currentTableContainer) {
            currentTableContainer.removeEventListener('scroll', handleScroll);
        }
    };
}, [hasMore, loading]);
    // 컴포넌트가 처음 렌더링될 때 모든 데이터를 불러옴
    useEffect(() => {
        getDatas();
        loadOptions(); // 대리점 및 FC 데이터를 불러옴
    }, [currentPage]);
const handleSearch=async()=>{
    if (tableContainerRef.current) {
        tableContainerRef.current.scrollTop = 0

    }
    setCurrentPage(1);
    setMembers([]); // 검색할 때 기존 테이블 데이터를 초기화
    setHasMore(true); // 새로운 검색 시 더 로드할 데이터가 있음을 true로 설정
    getDatas();
}
const handleSearchAll=async()=>{
    if (tableContainerRef.current) {
        tableContainerRef.current.scrollTop = 0

    }
    setSelectedCompany(null)
    setSelectedFc(null)
    setDateRange({ startDate: null, endDate: null })
    setMonthCost("")
    setReceiptType("")
    setGender("")
    setSearchText("")
    setCurrentPage(1);
    setMembers([]); // 검색할 때 기존 테이블 데이터를 초기화
    setHasMore(true); // 새로운 검색 시 더 로드할 데이터가 있음을 true로 설정  
    loadAllDatas()
}
 // 엑셀 다운로드 기능
 const excelDown = () => {
    const filteredMembers = members.map((member) => ({
        "대리점명": member.company, // 대리점명
        "FC": member.fc, // FC명
        "고객명": member.member, // 고객명
        "연락처": member.phone, // 연락처
        "주민번호": member.num, // 주민번호
        "성별": member.gender === "1" ? "여자" : "남자", // 성별
        "월보험료": getMonthCost(member.monthCost) ?? "-", // 월보험료
        "접수유형": receiptOptions.filter((r) => r.value === member.receipt)[0]?.label ?? "-", // 접수유형
        "진행상태": statusOptions.filter((s) => s.value === member.status)[0]?.label ?? "-", // 진행상태
        "메모": member.memo, // 메모
        '시/도': member.address?.sido || "", // 시/도
        '시/군/구': member.address?.sigungu || "", // 시/군/구
        '읍/면/동': member.address?.eupmyeon || "" // 읍/면/동
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredMembers); // 엑셀 시트로 변환
    const workbook = XLSX.utils.book_new(); // 새로운 엑셀 파일 생성
    XLSX.utils.book_append_sheet(workbook, worksheet, "Members"); // 시트 추가

    // 현재 날짜와 시간을 파일명에 추가
    const fileName = `members_list_${moment().format('YYYY-MM-DD_HH:mm:ss')}.xlsx`;
    XLSX.writeFile(workbook, fileName); // 엑셀 파일을 저장
};

function excelDateToJSDate(serial) {
    const utc_days = serial - 25569;
    const date = new Date(utc_days * 86400 * 1000);
    date.setUTCDate(date.getUTCDate() + 1); // 시차 보정
    return date;
}
const excelUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array', raw: true }); // raw: true를 사용하여 원래 데이터 유지
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: "" });

        if (window.confirm("데이터를 등록하시겠습니까?")) {
            try {
                setLoading(true);
                for (const item of jsonData) {
                    // 주민번호 필드를 숫자로 체크하고 날짜로 변환
                    let num = item["주민번호"];
                    if (typeof num === 'number') {
                        console.log("주민번호 변환>>>000",num)
                        const dateObj = excelDateToJSDate(num);
                        console.log("주민번호 변환>>>111",dateObj)
                        num = moment(dateObj).format('YYYY-MM-DD');
                  
                    }

                    await addDoc(collection(db, 'member'), {
                        company: item["대리점명"],
                        fc: item["FC"],
                        member: item["고객명"],
                        phone: item["연락처"],
                        num: num, // 변환된 주민번호를 저장
                        gender: item["성별"] === "여자" ? "1" : "2",
                        monthCost: item["월보험료"],
                        receipt: receiptOptions.filter((r) => r.label === item["접수유형"])[0]?.value ,
                        status: statusOptions.filter((s) => s.label === item["진행상태"])[0]?.value,
                        memo: item["메모"],
                        address: {
                            sido: item["시/도"],
                            sigungu: item["시/군/구"],
                            eupmyeon: item["읍/면/동"]
                        },
                        createdAt: moment().format()
                    });
                }
                setLoading(false);
                alert("데이터가 성공적으로 등록되었습니다.");
                getDatas();
            } catch (error) {
                console.error("데이터 업로드 중 오류 발생:", error);
                alert("데이터 업로드 중 오류가 발생했습니다.");
                setLoading(false);
            }
        }
    };
    reader.readAsArrayBuffer(file);
};

    return (
        <div className='content'>
            <LoadingDialog open={loading} />
            <CreateMemberDialog open={openCreate} onMemberClose={onClose} />

            {(loginType==="admin"||loginType==="vice")&&<div className="create-row">
                <Button variant='contained' onClick={onOpen}>
                    고객 생성
                </Button>
                <div style={{
                    width:"30px"
                }}/>
                <Button variant='outlined' 
                sx={{
                    backgroundColor:"white"
                }}
                onClick={excelDown}>
                    엑셀 다운
                </Button>
                <div style={{
                    width:"30px"
                }}/>
               <Button
    variant='contained'
    sx={{
        backgroundColor: green4
    }}
    onClick={() => document.getElementById("excel-upload").click()}
>
    엑셀 업로드
</Button>
<input
    type="file"
    id="excel-upload"
    accept=".xlsx, .xls"
    style={{ display: "none" }}
    onChange={excelUpload}
/>
            </div>}

            <div className="search-row">
                 {/* 대리점 검색 Autocomplete */}
                {(loginType==="admin"||loginType==="vice")&& <DefaultAutoComplete

placeholder='대리점을 선택하세요'
width={"200px"}
idOptions={companyOptions}
selected={selectedCompany}
handleOptionChange={(e, newValue) => setSelectedCompany(newValue)}
/>}

{/* FC 검색 Autocomplete */}
{(loginType!="fc")&& <DefaultAutoComplete

placeholder='FC를 선택하세요'
width={"200px"}
idOptions={fcOptions}
selected={selectedFc}
handleOptionChange={(e, newValue) => setSelectedFc(newValue)}
/>}

            </div>
            <div className="search-row">
                {/* 사용자 정의 DateRangePicker */}
                <DateRangePicker
                    startDate={dateRange.startDate}
                    endDate={dateRange.endDate}
                    onChange={handleDateChange} // 날짜 변경 처리
                />

               
                {/* 월보험료 필터 */}
                <FormControl size="small" style={{ minWidth: 120 }}>
                    <InputLabel>월보험료</InputLabel>
                    <Select
                        value={monthCost}
                        onChange={(e) => setMonthCost(e.target.value)}
                        label="월보험료"
                        sx={{
                            backgroundColor:"white"
                        }}
                    >
                        {monthCostOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* 접수유형 필터 */}
                <FormControl size="small" style={{ minWidth: 120 }}>
                    <InputLabel>접수유형</InputLabel>
                    <Select
                        value={receiptType}
                        onChange={(e) => setReceiptType(e.target.value)}
                        label="접수유형"
                        sx={{
                            backgroundColor:"white"
                        }}
                    >
                        {receiptOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* 성별 필터 */}
                <FormControl size="small" style={{ marginLeft: '10px', minWidth: 120 }}>
                    <InputLabel>성별</InputLabel>
                    <Select
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        label="성별"
                        sx={{
                            backgroundColor:"white"
                        }}
                    >
                        <MenuItem value="1">여자</MenuItem>
                        <MenuItem value="2">남자</MenuItem>
                    </Select>
                </FormControl>

                {/* 검색 텍스트 입력 */}
                <TextField
                    size="small"
                    placeholder='고객명을 검색하세요.'
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    sx={{ width: "200px" ,backgroundColor:"white"}}
                />

                {/* 검색 버튼 */}
                <Button
                    variant='contained'
                    sx={{ minWidth: "100px" }}
                    onClick={handleSearch} // 검색 버튼을 누르면 데이터 조회
                >
                    검색
                </Button>
                <Button
                    variant='outlined'
                    sx={{ minWidth: "100px", marginLeft: '10px',backgroundColor:"white" }}
                    onClick={handleSearchAll} // 검색 초기화
                >
                    전체 보기
                </Button>
            </div>

            <MemberTable
            tableContainerRef={tableContainerRef}
            datas={members} handleSearchAll={handleSearchAll}/>
        </div>
    );
}

export default Member;
