import React, { useEffect, useRef, useState } from 'react';
import CompanyTable from './CompanyTable';
import { Button } from '@mui/material';
import './Company.css'
import CreateCompanyDialog from '../Component/Dialog/CreateCompanyDialog';
import { db } from '../Firebase/Firebase';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import LoadingDialog from '../Component/Dialog/LoadingDialog';
import DefaultTxtfield from '../Component/Txtfield/DefaultTxtfield';
import { ITEMS_PER_PAGE } from '../const/value';
function Company(props) {
    const [openCreate, setOpenCreate] = useState(false);
    const [datas,setDatas]=useState([])
    const [loading,setLoading]=useState(false)
    const [searchTxt,setSearchTxt]=useState("")
    const [hasMore, setHasMore] = useState(true); // 데이터가 더 있는지 확인
    const [currentPage, setCurrentPage] = useState(1);
// const loggedAdmin= localStorage.getItem("loginType")==="admin"
const tableContainerRef = useRef(null);
    const onClose = async () => {
        setOpenCreate(false);
        // getDatas(); // 계정 생성 후 다시 데이터를 가져옵니다.
    };
    const onOpen = async () => {
        setOpenCreate(true);
    };
    const onChangeSearchTxt=(e)=>{
        setSearchTxt(e.target.value)
    }
        // 첫 화면에서 모든 데이터를 가져오는 함수
        const loadAllDatas = async () => {
            try {
                setLoading(true);
                const companyQuery = collection(db, 'company');
                const q = query(companyQuery, orderBy('createdAt', 'desc'));
                const querySnapshot = await getDocs(q);
                const companyList = querySnapshot.docs.map((doc) => ({
                    docId: doc.id,
                    ...doc.data(),
                }));
                const paginatedData= companyList.slice(0, currentPage * ITEMS_PER_PAGE);
  
          
                setDatas(paginatedData);
                setHasMore(paginatedData.length === currentPage * ITEMS_PER_PAGE);
                setLoading(false);
            } catch (error) {
                console.error("데이터를 가져오는 중 오류 발생: ", error);
                setLoading(false);
            }
        };
    
        const getDatas = async () => {
            try {
                setLoading(true);
        
                let companyQuery = collection(db, 'company');
                let q = query(companyQuery, orderBy('createdAt', 'desc'));
        
                // // 날짜 범위가 선택된 경우 해당 범위로 필터링
                // if (dateRange.startDate && dateRange.endDate) {
                //     const start = new Date(dateRange.startDate).setHours(0, 0, 0, 0); // 시작 날짜
                //     const end = new Date(dateRange.endDate).setHours(23, 59, 59, 999); // 종료 날짜
                //     q = query(
                //         memQuery,
                //         where('createdAt', '>=', new Date(start)),
                //         where('createdAt', '<=', new Date(end)),
                //         orderBy('createdAt', 'desc')
                //     );
                // }
        
                // Firestore에서 모든 데이터를 가져옵니다.
                const querySnapshot = await getDocs(q);
                const companyList = querySnapshot.docs.map((doc) => ({
                    docId: doc.id,
                    ...doc.data(),
                }));
        
      const filtered=companyList.filter(c=>c.companyName.includes(searchTxt)||c.companyId.includes(searchTxt))
      const paginatedData= filtered.slice(0, currentPage * ITEMS_PER_PAGE);

          
                setDatas(paginatedData);
                setHasMore(paginatedData.length === currentPage * ITEMS_PER_PAGE);
          console.log("로컬스토리지>>>", localStorage)
                setLoading(false);
            } catch (error) {
                console.error("데이터를 가져오는 중 오류 발생: ", error);
                setLoading(false);
            }
        };
        const handleSearchAll=async()=>{
            if (tableContainerRef.current) {
                tableContainerRef.current.scrollTop = 0
        
            }
         
            setSearchTxt("")
            setCurrentPage(1);
            setDatas([]); // 검색할 때 기존 테이블 데이터를 초기화
            setHasMore(true); // 새로운 검색 시 더 로드할 데이터가 있음을 true로 설정  
            loadAllDatas()
        }
        // 컴포넌트가 처음 렌더링될 때 모든 데이터를 불러옴
        useEffect(() => {
            getDatas();
        }, [currentPage]);
        // 스크롤 감지 함수
const handleScroll = () => {
    if (tableContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 50 && hasMore && !loading) {
            // 페이지 번호 증가
            setCurrentPage((prevPage) => prevPage + 1);
        }
    }
};
const handleSearch=async()=>{
    if (tableContainerRef.current) {
        tableContainerRef.current.scrollTop = 0

    }
    setCurrentPage(1);
    setDatas([]); // 검색할 때 기존 테이블 데이터를 초기화
    setHasMore(true); // 새로운 검색 시 더 로드할 데이터가 있음을 true로 설정
    getDatas();
}
// 스크롤 이벤트 리스너 추가
useEffect(() => {
    const currentTableContainer = tableContainerRef.current;
    if (currentTableContainer) {
        currentTableContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
        if (currentTableContainer) {
            currentTableContainer.removeEventListener('scroll', handleScroll);
        }
    };
}, [hasMore, loading]);
    return (
        <div className='company-content'>
            <LoadingDialog open={loading}/>
            {/* 대리점 등록 창 */}
            <CreateCompanyDialog open={openCreate} onClose={onClose} loadAllDatas={loadAllDatas}/>
            <div className="create-row">
                <Button  variant='contained' onClick={onOpen}>
                    대리점 등록
                </Button>
            </div>
            <div className="company-create-row"></div>

            <div className="company-search-row">
                <DefaultTxtfield width={"700px"} placeholder='대리점명 , 아이디로 검색하세요.' value={searchTxt} onChange={onChangeSearchTxt}/>
                <Button variant='contained' onClick={handleSearch}>검색</Button>
                <Button variant='outlined'
                sx={{
                    backgroundColor:"white"
                }}
                onClick={handleSearchAll}
                >전체 보기</Button>
             </div>
            <CompanyTable datas={datas} tableContainerRef={tableContainerRef} handleSearchAll={handleSearchAll}/>
        </div>
    );
}

export default Company;