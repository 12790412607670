import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { addDoc, collection, getDocs } from "firebase/firestore"; // Firestore 관련 함수 추가
import DefaultTxtfield from '../Txtfield/DefaultTxtfield';
import DefaultTxtArea from '../TxtArea/DefaultTxtArea';
import LoadingDialog from './LoadingDialog';
import './CreateCompanyDialog.css';
import { db } from '../../Firebase/Firebase';

function CreateCompanyDialog({ open, onClose, loadAllDatas }) {
    const DialogContentSx = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "30px"
    };

    const [companyId, setCompanyId] = useState(""); // 자동 생성된 회사 ID 저장
    const [companyName, setCompanyName] = useState("");
    const [managerName, setManagerName] = useState("");
    const [phone, setPhone] = useState("");
    const [managerPhone, setManagerPhone] = useState("");
    const [email, setEmail] = useState("");
    const [bank, setBank] = useState("");
    const [bankName, setBankName] = useState("");
    const [bankNum, setBankNum] = useState("");
    const [pw, setPw] = useState("");
    const [pwChk, setPwChk] = useState("");
    const [memo, setMemo] = useState("");
    const [emailError, setEmailError] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    // Firestore에서 회사 ID 자동 생성
    useEffect(() => {
        const generateCompanyId = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "company"));
                const length = querySnapshot.size;
                const newCompanyId = `MD${String(length).padStart(6, '0')}`; // 6자리 숫자로 패딩 처리
                setCompanyId(newCompanyId);
            } catch (error) {
                console.error("회사 ID를 생성하는 중 오류 발생:", error);
            }
        };
        generateCompanyId(); // 함수 실행
    }, [open]); // 다이얼로그가 열릴 때마다 새 ID 생성

    const onChangeCompanyName = (e) => setCompanyName(e.target.value);
    const onChangeManagerName = (e) => setManagerName(e.target.value);
    const onChangePhone = (e) => {
        const value = e.target.value;
        const regex = /^[0-9-]*$/; // 숫자와 '-'만 입력 가능
        if (regex.test(value)) {
            setPhone(value);
        }
    };
    const onChangeManagerPhone = (e) => {
        const value = e.target.value;
        const regex = /^[0-9-]*$/; // 숫자와 '-'만 입력 가능
        if (regex.test(value)) {
            setManagerPhone(value);
        }
    };
    const onChangeEmail = (e) => {
        const value = e.target.value;
        setEmail(value);

        // 이메일 형식 검사 (간단한 정규식)
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
            setEmailError("올바른 이메일 형식이 아닙니다.");
        } else {
            setEmailError(""); // 형식이 맞으면 오류 메시지 제거
        }
    };
    const onChangeBank = (e) => setBank(e.target.value);
    const onChangeBankName = (e) => setBankName(e.target.value);
    const onChangeBankNum = (e) => setBankNum(e.target.value);
    const onChangePw = (e) => setPw(e.target.value);
    const onChangePwChk = (e) => setPwChk(e.target.value);
    const onChangeMemo = (e) => setMemo(e.target.value);

    const validate = () => {
        const newErrors = {};
        if (!companyName) newErrors.companyName = "대리점명은 필수 입력 항목입니다.";
        if (!phone) newErrors.phone = "휴대전화는 필수 입력 항목입니다.";
        if (!pw) newErrors.pw = "비밀번호는 필수 입력 항목입니다.";
        if (pw !== pwChk) newErrors.pwChk = "비밀번호가 일치하지 않습니다.";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (validate()) {
            // Firestore에 데이터 추가
            try {
                setLoading(true);
                await addDoc(collection(db, "company"), {
                    companyId: companyId, // 자동 생성된 회사 ID 저장
                    companyName: companyName,
                    managerName: managerName,
                    phone: phone,
                    managerPhone: managerPhone,
                    email: email,
                    bank: bank,
                    bankName: bankName,
                    bankNum: bankNum,
                    memo: memo,
                    pw: pw,
                    createdAt: new Date(),
                });
                onClose();
                loadAllDatas();
                alert("대리점 등록 성공");

                // 입력값 초기화
                setCompanyName("");
                setManagerName("");
                setPhone("");
                setManagerPhone("");
                setEmail("");
                setBank("");
                setBankName("");
                setBankNum("");
                setPw("");
                setPwChk("");
                setMemo("");
                setLoading(false);
            } catch (error) {
                alert(`대리점 등록 에러: ${error}`);
                console.error("Error adding document: ", error);
            }
        }
    };

    return (
        <Dialog 
            maxWidth="lg"
            open={open}
            onClose={onClose}
        >
            <LoadingDialog open={loading}/>
            <DialogTitle>대리점 등록</DialogTitle>
            <DialogContent sx={DialogContentSx}>
                <div className="c-dialog-row">
                    <div className="dialog-left">
                        <Typography variant='h6'>기본 정보</Typography>
                        <Typography>아이디: {companyId}</Typography> {/* 생성된 회사 ID 표시 */}
                        <DefaultTxtfield 
                            width={"500px"} 
                            placeholder='대리점명' 
                            value={companyName} 
                            onChange={onChangeCompanyName} 
                            error={!!errors.companyName}
                            helperText={errors.companyName} 
                        />
                        <DefaultTxtfield 
                            width={"500px"} 
                            placeholder='담당자명' 
                            value={managerName} 
                            onChange={onChangeManagerName} 
                        />
                        <DefaultTxtfield 
                            width={"500px"} 
                            placeholder='휴대전화(ex: 010-1212-1212)' 
                            value={phone} 
                            onChange={onChangePhone} 
                            error={!!errors.phone}
                            helperText={errors.phone} 
                        />
                        <DefaultTxtfield 
                            width={"500px"} 
                            placeholder='담당자 연락처(ex: 010-1212-1212)' 
                            value={managerPhone} 
                            onChange={onChangeManagerPhone} 
                        />
                        <DefaultTxtfield 
                            width={"500px"} 
                            placeholder='이메일(ex: suhyeon1212@naver.com)' 
                            value={email} 
                            onChange={onChangeEmail} 
                            error={!!emailError}
                            helperText={emailError}
                        />
                    </div>
                    <div className="divider" />
                    <div className="dialog-right">
                        <Typography variant='h6'>정산 정보</Typography>
                        <DefaultTxtfield 
                            width={"500px"} 
                            placeholder='은행명' 
                            value={bank} 
                            onChange={onChangeBank} 
                        />
                        <DefaultTxtfield 
                            width={"500px"} 
                            placeholder='예금주명' 
                            value={bankName} 
                            onChange={onChangeBankName} 
                        />
                        <DefaultTxtfield 
                            width={"500px"} 
                            placeholder='계좌번호' 
                            value={bankNum} 
                            onChange={onChangeBankNum} 
                        />
                        <DefaultTxtfield 
                            width={"500px"} 
                            placeholder='비밀번호' 
                            value={pw} 
                            onChange={onChangePw} 
                            error={!!errors.pw}
                            helperText={errors.pw} 
                        />
                        <DefaultTxtfield 
                            width={"500px"} 
                            placeholder='비밀번호 확인' 
                            value={pwChk} 
                            onChange={onChangePwChk} 
                            error={!!errors.pwChk}
                            helperText={errors.pwChk} 
                        />
                    </div>
                </div>
                <DefaultTxtArea
                    placeholder='메모를 입력하세요.'
                    width={"100%"}
                    value={memo}
                    onChange={onChangeMemo}
                />
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={onClose}>닫기</Button>
                <Button variant='contained' onClick={handleSubmit}>등록</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateCompanyDialog;
