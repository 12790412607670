import { TextareaAutosize } from '@mui/material';
import React from 'react';

function DefaultTxtArea({value,onChange,width=null,placeholder=""}) {
    return (
       <TextareaAutosize
       placeholder={placeholder}
       style={{
        minHeight:"200px",
        resize:"none",
        width:width
       }}
       value={value}
       onChange={onChange}
       />
    );
}

export default DefaultTxtArea;