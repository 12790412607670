import { TextField } from '@mui/material';
import React from 'react';
import './DateRangePicker.css';

function DateRangePicker({ startDate, endDate, onChange }) {
    return (
        <div className='dt-range'>
            <TextField
                size='small'
                type='date'
                name='startDate'
                sx={{
                    backgroundColor:'white'
                }}
                value={startDate || ''} // 시작 날짜
                onChange={onChange} // 날짜 변경 시 호출
                InputLabelProps={{
                    shrink: true, // 날짜 값이 있을 때 라벨을 상단으로 이동
                }}
            />
            <span>~</span>
            <TextField
                size='small'
                type='date'
                name='endDate'
                sx={{
                    backgroundColor:'white'
                }}
                value={endDate || ''} // 종료 날짜
                onChange={onChange} // 날짜 변경 시 호출
                InputLabelProps={{
                    shrink: true, // 날짜 값이 있을 때 라벨을 상단으로 이동
                }}
            />
        </div>
    );
}

export default DateRangePicker;
