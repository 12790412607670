import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { StyledTableCell, StyledTableRow } from '../const/style';
import { FaPencilAlt } from 'react-icons/fa';
import { formatDate, getGradeName } from '../const/func';
import IOSSwitchComponent from '../Component/Switch/IOSSwitch';
import EditFCDialog from '../Component/Dialog/EditFCDialog';

function FCTable({datas,loadAllDatas,tableContainerRef}) {
  const [editRow,setEditRow]=useState(null)
  const loginType = localStorage.getItem("loginType");
  const onOpenEdit=(row)=>{
    setEditRow(row)
  }
  const onCloseEdit=()=>{
    setEditRow(null)
    loadAllDatas()
  }
    return (
        <TableContainer 
        ref={tableContainerRef}
        variant='outlined'
    component={Paper}
    sx={{maxHeight: '650px', overflowY: 'auto' }}>
      <EditFCDialog open={editRow!=null} onClose={onCloseEdit} row={editRow} />
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow
        
            >
       
       <StyledTableCell align="left">No.</StyledTableCell>
       <StyledTableCell align="left">대리점</StyledTableCell>
       <StyledTableCell align="left">아이디</StyledTableCell>
              <StyledTableCell align="left">FC명</StyledTableCell>
              <StyledTableCell align="left">휴대폰번호</StyledTableCell>
              {/* <StyledTableCell align="left">운영여부</StyledTableCell> */}
              <StyledTableCell align="left">등록일자</StyledTableCell>
              <StyledTableCell align="left">수정</StyledTableCell>
  
            </TableRow>
          </TableHead>
          <TableBody>
            {datas.length===0?(<TableRow>
                  <TableCell colSpan={8} align="center">데이터 없음(검색조건을 수정하세요)</TableCell>
                </TableRow>):(datas.map((row, idx) => (
              <StyledTableRow key={idx}  
              sx={{
               
                cursor:"pointer"
              }}
          
             >
                <StyledTableCell align="left" >{idx+1}</StyledTableCell>
                <StyledTableCell align="left" >{`${row.companyInfo}`??"-"}</StyledTableCell>
                <StyledTableCell align="left" >{row.fcId??"-"}</StyledTableCell>
                <StyledTableCell align="left" >{row.fcName??"-"}</StyledTableCell>
                <StyledTableCell align="left" >{row.fcContact??"-"}</StyledTableCell>
                {/* <StyledTableCell align="left" ><IOSSwitchComponent/></StyledTableCell> */}
                <StyledTableCell align="left" >{formatDate(row.createdAt)??"-"}</StyledTableCell>
                <StyledTableCell align="left" ><IconButton
             
                onClick={()=>{onOpenEdit(row)}}
                ><FaPencilAlt/></IconButton></StyledTableCell>
            
  
               
            
              </StyledTableRow>
            )))}
          </TableBody>
        </Table>
      </TableContainer>
    );
}

export default FCTable;