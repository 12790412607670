import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { StyledTableCell, StyledTableRow } from '../const/style';
import { FaPencilAlt } from 'react-icons/fa';
import { formatDate } from '../const/func';

import EditNoticeDialog from '../Component/Dialog/EditNoticeDialog';

function NoticeTable({ datas, tableContainerRef, loadAllDatas }) {
  const [editRow, setEditRow] = useState(null);
  const dataFilterId = localStorage.getItem("dataFilterId");
  const loginType = localStorage.getItem("loginType");
  const onOpenEdit = (row) => {
    setEditRow(row);
  };

  const onCloseEdit = () => {
    setEditRow(null);
    loadAllDatas();
  };

  return (
    <TableContainer
      ref={tableContainerRef}
      variant='outlined'
      component={Paper}
      sx={{ maxHeight: '650px', overflowY: 'auto' }}
    >
      <EditNoticeDialog open={editRow != null} onClose={onCloseEdit} row={editRow} />
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">No.</StyledTableCell>
            <StyledTableCell align="left">작성일</StyledTableCell>
            <StyledTableCell align="left">작성자</StyledTableCell>
            <StyledTableCell align="left">제목</StyledTableCell>
            <StyledTableCell align="left">내용</StyledTableCell>
            <StyledTableCell align="left">수정</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datas.length === 0 ? (
            <TableRow>
              <TableCell colSpan={7} align="center">데이터 없음</TableCell>
            </TableRow>
          ) : (
            datas.map((row, idx) => (
              <StyledTableRow
                key={idx}
                sx={{ cursor: "pointer" }}
              >
                <StyledTableCell align="left">{idx + 1}</StyledTableCell>
                <StyledTableCell align="left">{formatDate(row.createdAt) ?? "-"}</StyledTableCell>
                <StyledTableCell align="left">{row.authorName ?? "-"}</StyledTableCell>

                {/* 제목 셀에 툴팁 추가 */}
                <Tooltip title={row.title ?? "-"} arrow  
          
                sx={{
                    "& .MuiTooltip-tooltip": {
                      fontSize: "20px", // 툴팁의 폰트 크기를 20px로 설정
                    }
                  }}> 
                  <StyledTableCell
                    align="left"
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '150px'
                    }}
                  >
                    {row.title ?? "-"}
                  </StyledTableCell>
                </Tooltip>

                {/* 내용 셀에 툴팁 추가 */}
                <Tooltip title={row.content ?? "-"} arrow>
                  <StyledTableCell
                    align="left"
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '150px'
                    }}
                  >
                    {row.content ?? "-"}
                  </StyledTableCell>
                </Tooltip>

                <StyledTableCell align="left">
                  {(dataFilterId === row.authorId||loginType==="admin"||loginType==="vice") && <IconButton onClick={() => { onOpenEdit(row); }}>
                    <FaPencilAlt />
                  </IconButton>}
                </StyledTableCell>
              </StyledTableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default NoticeTable;
