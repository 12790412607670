import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DefaultTxtfield from '../Txtfield/DefaultTxtfield';
import DefaultTxtArea from '../TxtArea/DefaultTxtArea';
import DefaultAutoComplete from '../AutoComplete/DefaultAutoComplete';
import { addDoc, collection, getDocs } from "firebase/firestore";
import { db } from '../../Firebase/Firebase'; // Firebase 설정 파일에서 db import
import DefaultSelect from '../Select/DefaultSelect';

function CreateFCDialog({ open, onClose, loadAllDatas }) {
    const [fcId, setFcId] = useState(""); // 자동 생성된 FC ID
    const [selected, setSelected] = useState("2");
    const [fcName, setFcName] = useState("");
    const [fcContact, setFcContact] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [memo, setMemo] = useState("");
    const [emailError, setEmailError] = useState("");
    const [dealershipOptions, setDealershipOptions] = useState([]); // 대리점 리스트
    const [selectedDealership, setSelectedDealership] = useState(null); // 선택된 대리점 ID 저장
    const [loading, setLoading] = useState(false); // 로딩 상태 관리
    const [errors, setErrors] = useState({
        mobile: '',
        password: '',
        passwordConfirm: ''
    });
    const loginType = localStorage.getItem("loginType");
   const dataFilterId= localStorage.getItem("dataFilterId");
    // Firestore에서 FC ID 자동 생성
    useEffect(() => {
        const generateFcId = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "fc"));
                const length = querySnapshot.size;
                const newFcId = `FC${String(length).padStart(6, '0')}`; // 6자리 숫자로 패딩 처리
                setFcId(newFcId);
            } catch (error) {
                console.error("FC ID를 생성하는 중 오류 발생:", error);
            }
        };
        generateFcId(); // 함수 실행
    }, [open]); // 다이얼로그가 열릴 때마다 새 ID 생성

    // Firestore에서 대리점 리스트 가져오기
    useEffect(() => {
        // 대리점 페이지면 대리점 검색 없애고 텍스트로 넣기
        if (loginType === "company") {
            // 대리점으로 로그인 했으면 company컬렉션에서 dataFilterId를 docId로 써서 `${company.companyName} ${company.managerName}`을 selectedDealership.label에 넣고, selectedDealership.id에 dataFilterId를 넣자,
            const fetchCompanyData = async () => {
                try {
                    const docRef = collection(db, "company");
                    const querySnapshot = await getDocs(docRef);
                    const selectedDoc = querySnapshot.docs.find(doc => doc.id === dataFilterId);
    
                    if (selectedDoc) {
                        const companyData = selectedDoc.data();
                        const selectedDealership = {
                            id: dataFilterId,
                            label: `${companyData.companyName} ${companyData.managerName}`,
                        };
                        setSelectedDealership(selectedDealership);
                    }
                } catch (error) {
                    console.error("대리점 정보를 가져오는 중 오류 발생:", error);
                }
            };
    
            fetchCompanyData(); // 대리점 정보 가져오기 함수 실행
        } else {
            // Firestore에서 대리점 리스트 가져오기
            const fetchDealerships = async () => {
                setLoading(true); // 로딩 시작
                try {
                    const querySnapshot = await getDocs(collection(db, "company"));
    
                    const options = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        label: `${doc.data().companyName} ${doc.data().managerName}`, // 컬렉션에서 대리점 이름을 가져와 표시
                    }));
                    setDealershipOptions(options); // 대리점 리스트 설정
                } catch (error) {
                    console.error("대리점 리스트를 가져오는 중 오류 발생:", error);
                } finally {
                    setLoading(false); // 로딩 완료
                }
            };
    
            fetchDealerships(); // 대리점 리스트 가져오기 함수 실행
        }
    }, []); // 다이얼로그가 열릴 때마다 새로 실행

    const handleFcNameChange = (e) => setFcName(e.target.value);
    const handleFcContactChange = (e) => {
        const value = e.target.value;
        const regex = /^[0-9-]*$/; // 숫자와 '-'만 입력 가능
        if (regex.test(value)) {
            setFcContact(value) 
        }
       };
    const handleMobileChange = (e) => {
        const value = e.target.value;
        const regex = /^[0-9-]*$/; // 숫자와 '-'만 입력 가능
        if (regex.test(value)) {
            setMobile(value);
        }
    };
    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
            setEmailError("올바른 이메일 형식이 아닙니다.");
        } else {
            setEmailError("");
        }
    };
    const handlePasswordChange = (e) => setPassword(e.target.value);
    const handlePasswordConfirmChange = (e) => setPasswordConfirm(e.target.value);
    const handleMemoChange = (e) => setMemo(e.target.value);

    // 입력값 유효성 검사
    const validate = () => {
        const newErrors = {};
        if (!mobile) {
            newErrors.mobile = "휴대전화는 필수 입력 항목입니다.";
        }
        if (!password) {
            newErrors.password = "비밀번호는 필수 입력 항목입니다.";
        }
        if (!passwordConfirm) {
            newErrors.passwordConfirm = "비밀번호 확인은 필수 입력 항목입니다.";
        }
        if (password && passwordConfirm && password !== passwordConfirm) {
            newErrors.passwordConfirm = "비밀번호가 일치하지 않습니다.";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validate()) {
            return;
        }
        try {
            // Firestore의 "fc" 컬렉션에 데이터 저장
            await addDoc(collection(db, "fc"), {
                fcId: fcId, // 생성된 FC ID 저장
                companyId: selectedDealership?.id || "", // 선택된 대리점 ID 저장
                fcName: fcName,
                fcContact: fcContact,
                mobile: mobile,
                email: email,
                password: password,
                memo: memo,
                grade: selected,
                createdAt: new Date(),
            });

            alert("FC 등록이 성공적으로 완료되었습니다.");
            loadAllDatas(); // 데이터 갱신
            onClose(); // 다이얼로그 닫기

            // 입력값 초기화
            setFcName("");
            setFcContact("");
            setMobile("");
            setEmail("");
            setPassword("");
            setPasswordConfirm("");
            setMemo("");
            setErrors({});
        } catch (error) {
            alert(`FC 등록 중 오류가 발생했습니다: ${error.message}`);
            console.error("Error adding document: ", error);
        }
    };

    const handleDealershipSelect = (event, newValue) => {
        setSelectedDealership(newValue); // 선택된 대리점 설정
    };

    const handleDealershipInputChange = (event, newInputValue) => {
        // 필요 시 입력값 변화 처리 가능
    };
    const accountOptions = [
        { value: "1", label: "부대표" },
        { value: "2", label: "일반" },
    ];
    const onChange = (e) => {
        const sel = e.target.value;
        setSelected(sel);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md">
            <DialogTitle>FC 등록</DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <Typography>아이디: {fcId}</Typography> {/* 생성된 FC ID 표시 */}
              {loginType==="company"?<Typography>{selectedDealership?selectedDealership.label:"-"}</Typography>:  <DefaultAutoComplete
                    idOptions={dealershipOptions} // 대리점 옵션 전달
                    loading={loading} // 로딩 상태 전달
                    handleOptionChange={handleDealershipSelect} // 선택 처리 함수 전달
                    handleInputChange={handleDealershipInputChange} // 입력 처리 함수 전달
                    placeholder="대리점 검색(ex:부산대리점 김주현)"
                />}
                <DefaultTxtfield
                    width={"500px"}
                    placeholder='FC명'
                    value={fcName}
                    onChange={handleFcNameChange}
                />
                <DefaultTxtfield
                    width={"500px"}
                    placeholder='FC연락처'
                    value={fcContact}
                    onChange={handleFcContactChange}
                />
                <DefaultTxtfield
                    width={"500px"}
                    placeholder='휴대전화'
                    value={mobile}
                    onChange={handleMobileChange}
                    error={!!errors.mobile}
                    helperText={errors.mobile}
                />
                {/* <DefaultSelect options={accountOptions} value={selected} onChange={onChange} /> */}
                <DefaultTxtfield
                    width={"500px"}
                    placeholder='이메일'
                    value={email}
                    onChange={handleEmailChange}
                />
                <DefaultTxtfield
                    width={"500px"}
                    placeholder='비밀번호'
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    error={!!errors.password}
                    helperText={errors.password}
                />
                <DefaultTxtfield
                    width={"500px"}
                    placeholder='비밀번호 확인'
                    type="password"
                    value={passwordConfirm}
                    onChange={handlePasswordConfirmChange}
                    error={!!errors.passwordConfirm}
                    helperText={errors.passwordConfirm}
                />
                <DefaultTxtArea
                    placeholder='메모'
                    value={memo}
                    onChange={handleMemoChange}
                />
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={onClose}>닫기</Button>
                <Button variant='contained' onClick={handleSubmit}>등록</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateFCDialog;
