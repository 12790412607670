import { TextField } from '@mui/material';
import React from 'react';

function DefaultDatePicker({value,onChange}) {
    return (
        <TextField
        sx={{
            minWidth:"160px"
        }}
            size='small'
            type='date'
            value={value}
            onChange={onChange}
            />
    );
}

export default DefaultDatePicker;