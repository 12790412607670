import { Dialog, DialogContent, DialogTitle, DialogActions, TextField, Button, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, addDoc } from "firebase/firestore";
import { db } from '../../Firebase/Firebase'; // Firebase 설정 파일에서 db import
import LoadingDialog from './LoadingDialog';
import moment from 'moment';

function CreateNoticeDialog({ open, onClose }) {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [author, setAuthor] = useState(''); // 작성자 상태
    const [currentDate, setCurrentDate] = useState('');
    const [loading,setLoading]=useState(false)
    const loginType = localStorage.getItem("loginType");
    const dataFilterId = localStorage.getItem("dataFilterId");

    // 제목 입력 핸들러
    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    // 내용 입력 핸들러
    const handleContentChange = (e) => {
        setContent(e.target.value);
    };

    // 저장 버튼 클릭 핸들러
    const handleSubmit = async () => {
        if (title.trim() === '' || content.trim() === '') {
            alert("제목과 내용을 모두 입력해 주세요.");
            return;
        }

        // 공지사항 저장 처리
        try {
            await onSubmit(); // Firestore에 저장하는 함수 호출

            alert("공지사항이 성공적으로 저장되었습니다.");
            
            // 폼 초기화
            setTitle('');
            setContent('');

            // 다이얼로그 닫기
            onClose();
        } catch (error) {
            console.error("공지사항 저장 중 오류 발생:", error);
            alert("공지사항 저장 중 오류가 발생했습니다.");
        }
    };

    // 오늘 날짜 가져오기
    useEffect(() => {
        const today = new Date();
        const formattedDate = moment(today).format("YYYY-MM-DD HH:MM:SS")
        setCurrentDate(formattedDate);
    }, []);

    // Firestore에 공지사항 저장하는 함수
    const onSubmit = async () => {
        try {
            setLoading(true)
            // notice 컬렉션에 데이터 추가
            await addDoc(collection(db, "notice"), {
                title: title,
                content: content,
                // author: author,
                date: currentDate,
                createdAt: new Date() ,// 추가적으로 생성 시간 저장
                authorId:dataFilterId,
                authorType:loginType,
            });
            setLoading(false)
        } catch (error) {
            console.error("Firestore 저장 중 오류 발생:", error);
            throw error;
        }
    };

    // 작성자 정보를 가져오기 위한 useEffect
    useEffect(() => {
        const fetchAuthor = async () => {
            if (loginType === "admin") {
                setAuthor("관리자");
            } else if (loginType === "fc") {
                // Firestore에서 FC 컬렉션의 fcName 가져오기
                try {
                    const docRef = doc(collection(db, "fc"), dataFilterId);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        setAuthor(docSnap.data().fcName);
                    } else {
                        setAuthor("FC");
                    }
                } catch (error) {
                    console.error("FC 정보를 가져오는 중 오류 발생:", error);
                    setAuthor("FC");
                }
            } else if (loginType === "company") {
                // Firestore에서 Company 컬렉션의 companyName 가져오기
                try {
                    const docRef = doc(collection(db, "company"), dataFilterId);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        setAuthor(docSnap.data().companyName);
                    } else {
                        setAuthor("대리점");
                    }
                } catch (error) {
                    console.error("대리점 정보를 가져오는 중 오류 발생:", error);
                    setAuthor("대리점");
                }
            }
        };

        fetchAuthor();
    }, [loginType, dataFilterId]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <LoadingDialog  open={loading}/>
            <DialogTitle>공지사항 작성</DialogTitle>
            <DialogContent>
                {/* 오늘 날짜 표시 */}
                <Typography variant="subtitle1" gutterBottom>
                    {currentDate}
                </Typography>

                {/* 작성자 표시 */}
                <Typography variant="subtitle2" gutterBottom>
                    작성자: {author}
                </Typography>

                {/* 제목 입력 */}
                <TextField
                    margin="dense"
                    label="제목"
                    fullWidth
                    value={title}
                    onChange={handleTitleChange}
                />
                {/* 내용 입력 */}
                <TextField
                    margin="dense"
                    label="내용"
                    fullWidth
                    multiline
                    rows={20}
                    value={content}
                    onChange={handleContentChange}
                />
            </DialogContent>
            <DialogActions>
                {/* 취소 버튼 */}
                <Button variant="outlined" onClick={onClose}>
                    취소
                </Button>
                {/* 저장 버튼 */}
                <Button variant="contained" onClick={handleSubmit}>
                    저장
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateNoticeDialog;
