import { TextField } from '@mui/material';
import React from 'react';

function DefaultTxtfield({
    disabled=false,
    type="text",placeholder="",onChange,value,width=null,error,helperText,fullWidth=false}) {
    return (
        <TextField
        fullWidth={fullWidth}
        disabled={disabled}
        type={type}
        size='small'
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        error={error}
        helperText={helperText}
        sx={{backgroundColor:"white",
            width:width
        }}
        />
    );
}

export default DefaultTxtfield;