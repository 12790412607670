import React, { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { db } from '../../Firebase/Firebase'; // Firestore 인스턴스 가져오기
import { collection, query, where, getDocs } from 'firebase/firestore';

const DefaultAutoComplete = ({ width=null,placeholder = "", selected,idOptions, handleOptionChange,handleInputChange ,loading}) => {
 
  

   

   
    return (
        <div>
            <Autocomplete
                freeSolo
                onSelect={(e)=>{
                    console.log("select>>>",e.target.value)
                }}
                options={idOptions}
                loading={loading} // 로딩 상태를 표시
                onChange={handleOptionChange} // 선택된 값 변경 시 호출
                onInputChange={handleInputChange} // 입력값이 변경될 때 호출
                renderInput={(params) => (
                    <TextField
                        size='small'
                        {...params}
                        placeholder={placeholder}
                        variant="outlined"
                        sx={{
                            width:width,
                            backgroundColor:"white",
                            height: 42, // TextField의 전체 높이를 42px로 설정
                            '& .MuiInputBase-root': {
                                height: 42, // Input 내부의 높이 조정
                            },
                            '& .MuiInputLabel-root': {
                                lineHeight: '42px', // 라벨 위치 조정
                            }
                        }}
                    />
                )}
            />
        </div>
    );
};

export default DefaultAutoComplete;
