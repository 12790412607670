import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { StyledTableCell, StyledTableRow } from '../const/style';
import { FaPencilAlt } from 'react-icons/fa';
import { formatDate } from '../const/func';

import EditNoticeDialog from '../Component/Dialog/EditNoticeDialog';
import EditViceDialog from '../Component/Dialog/EditViceDialog';

function ViceTable({ datas, tableContainerRef, loadAllDatas }) {
  const [editRow, setEditRow] = useState(null);
  const dataFilterId = localStorage.getItem("dataFilterId");

  const onOpenEdit = (row) => {
    setEditRow(row);
  };

  const onCloseEdit = () => {
    setEditRow(null);
    loadAllDatas();
  };

  return (
    <TableContainer
      ref={tableContainerRef}
      variant='outlined'
      component={Paper}
      sx={{ maxHeight: '650px', overflowY: 'auto' }}
    >
      <EditViceDialog open={editRow != null} onClose={onCloseEdit} row={editRow} />
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">No.</StyledTableCell>
            <StyledTableCell align="left">아이디</StyledTableCell>
            <StyledTableCell align="left">이름</StyledTableCell>
            <StyledTableCell align="left">비밀번호</StyledTableCell>
            <StyledTableCell align="left">등록일자</StyledTableCell>
            <StyledTableCell align="left">수정</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datas.length === 0 ? (
            <TableRow>
              <TableCell colSpan={7} align="center">데이터 없음</TableCell>
            </TableRow>
          ) : (
            datas.map((row, idx) => (
              <StyledTableRow
                key={idx}
                sx={{ cursor: "pointer" }}
              >
                <StyledTableCell align="left">{idx + 1}</StyledTableCell>
                <StyledTableCell align="left">{row.id ?? "-"}</StyledTableCell> 
                <StyledTableCell align="left">{row.name ?? "-"}</StyledTableCell> 
                <StyledTableCell align="left">{row.password ?? "-"}</StyledTableCell>

                   <StyledTableCell align="left">{formatDate(row.createdAt) ?? "-"}</StyledTableCell>
            

           

                <StyledTableCell align="left">
                  {<IconButton onClick={() => { onOpenEdit(row); }}>
                    <FaPencilAlt />
                  </IconButton>}
                </StyledTableCell>
              </StyledTableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ViceTable;
