import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Box, Typography, Tabs, Tab, CircularProgress } from '@mui/material';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db } from '../Firebase/Firebase';
import './Login.css'
import LoginSrc from './login-img.jpg'

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [selectedTab, setSelectedTab] = useState(0); // 0: Admin 로그인, 1: FC 로그인, 2: 대리점 로그인, 3: 부대표 로그인
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // 페이지 이동을 위한 네비게이트 훅

  useEffect(() => {
    // 이미 로그인된 사용자는 /account로 리다이렉트
    const isLoggedIn = localStorage.getItem('isLoggedIn') === "true";
    if (isLoggedIn) {
      navigate("/member");
    }
  }, [navigate]);

  const handleLogin = async () => {
    try {
      let querySnapshot;

      if (selectedTab === 0) {
        // Admin 로그인 시도
        const adminQuery = query(
          collection(db, 'admin'),
          where('id', '==', email),
          where('pw', '==', password)
        );
        querySnapshot = await getDocs(adminQuery);
      } else if (selectedTab === 1) {
        // FC 로그인 시도
        const fcQuery = query(
          collection(db, 'fc'),
          where('fcId', '==', email),
          where('password', '==', password)
        );
        querySnapshot = await getDocs(fcQuery);
      } else if (selectedTab === 2) {
        // 대리점 로그인 시도
        const companyQuery = query(
          collection(db, 'company'),
          where('companyId', '==', email),
          where('pw', '==', password)
        );
        querySnapshot = await getDocs(companyQuery);
      } else {
        // 부대표 로그인 시도
        const viceQuery = query(
          collection(db, 'vice'),
          where('id', '==', email),
          where('password', '==', password)
        );
        querySnapshot = await getDocs(viceQuery);
      }

      if (!querySnapshot.empty) {
        setLoading(true);
        const dataFilterId = querySnapshot.docs.map(d => d.id)[0];

        // 로그인 타입 저장
        const loginTypes = ["admin", "fc", "company", "vice"];
        localStorage.setItem("loginType", loginTypes[selectedTab]);
        localStorage.setItem("dataFilterId", dataFilterId);

        // 로그인 성공 시, 로컬 스토리지에 로그인 상태 저장
        localStorage.setItem('isLoggedIn', 'true');
        window.history.replaceState(null, '', '/');
        setLoading(false);  
        navigate('/member');
      } else {
        setError('아이디 또는 비밀번호가 일치하지 않습니다.');
      }
    } catch (err) {
      console.error('Error logging in: ', err);
      setError('로그인 중 오류가 발생했습니다.');
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setEmail('');
    setPassword('');
    setError('');
  };

  return (
    <div className='login-content'>
      <div className="login-bg-img-container"><img className="login-bg-img" src={LoginSrc} loading="eager" /></div>
      <Container maxWidth="sm">
        <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          {/* <Typography variant="h4" gutterBottom>
            INCO DB
          </Typography> */}
        <img src='./logo.png' style={{
          width:"200px"
        }}/>
          {/* Tabs for Admin, FC, Company, and Vice login */}
          <Tabs value={selectedTab} onChange={handleTabChange} centered>
            <Tab label="대표자 로그인" />
            <Tab label="FC 로그인" />
            <Tab label="대리점 로그인" />
            <Tab label="부대표 로그인" />
          </Tabs>

          <TextField
            label={
              selectedTab === 0 ? "대표자 아이디" :
              selectedTab === 1 ? "FC 아이디" :
              selectedTab === 2 ? "대리점 아이디" : "부대표 아이디"
            }
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="비밀번호"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && (
            <Typography color="error" variant="body2" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2, height: "60px" }}
            onClick={handleLogin}
          >
            {loading ? <CircularProgress sx={{ color: "white" }} /> : 
              selectedTab === 0 ? "대표자 로그인" : 
              selectedTab === 1 ? "FC 로그인" : 
              selectedTab === 2 ? "대리점 로그인" : "부대표 로그인"
            }
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default Login;
