// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCAlrPU_15CvTzJrxlIa8vrRmuYc4vIrSo",
  authDomain: "call-admin-41311.firebaseapp.com",
  projectId: "call-admin-41311",
  storageBucket: "call-admin-41311.appspot.com",
  messagingSenderId: "315018855427",
  appId: "1:315018855427:web:8f93e6dc3cdc95346e7a36",
  measurementId: "G-K712DW1JRJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db=getFirestore(app)
export const storage = getStorage(app)
// {
//   "hosting": {
//     "public": "build",
//     "headers": [ {
//       "source" : "**/*.@(eot|otf|ttf|ttc|woff|font.css)",
//       "headers" : [ {
//         "key" : "Access-Control-Allow-Origin",
//         "value" : "*"
//       } ]
//     }, {
//       "source" : "**/*.@(js|css)",
//       "headers" : [ {
//         "key" : "Cache-Control",
//         "value" : "max-age=604800"
//       } ]
//     }, {
//       "source" : "**/*.@(jpg|jpeg|gif|png)",
//       "headers" : [ {
//         "key" : "Cache-Control",
//         "value" : "max-age=604800"
//       } ]
//     }, {
//       "source" : "404.html",
//       "headers" : [ {
//         "key" : "Cache-Control",
//         "value" : "max-age=300"
//       } ]
//     } ],
//         "ignore": [
//           "firebase.json",
//           "**/.*",
//           "**/node_modules/**"
//         ],
//         "rewrites": [
//           {
//             "source": "**",
//             "destination": "/index.html"
//           }
//         ]
//       }
// }
