import moment from "moment";

// 날짜 포맷팅 함수 (moment 사용)
export const formatDate = (timestamp) => {
    if (!timestamp) return "-";
    
    // Firestore Timestamp를 moment로 변환하여 원하는 형식으로 포맷
    return moment(timestamp.toDate()).format('YYYY.MM.DD HH:mm:ss');
};
export const getGradeName=(grade)=>{
    if (grade==="1") {
        return "부대표"
    } else if (grade==="2") {
        return "일반"
    }
    else{
         return "-"
    }
}
export const getSidebarName=(location)=>{
    if (location==="/account") {
        return "계정 생성"
    }
    else if(location==="/member"){
        return "고객 관리"
    }
    else if(location==="/company"){
        return "대리점 관리"
    }
    else if(location==="/fc"){
        return "FC 관리"
    }
    else if(location==="/notice"){
        return "공지사항"
    } else if(location==="/vice"){
        return "부대표 관리"
    }
    else {
        return "-"
    }
}
export const getGender=(type)=>{
if (type==="1") {
    return "여자"
} else {
    return "남자"
}
}
export const getMonthCost=(type)=>{
    if (type==="0") {
        return "없음"
    } else {
        const parsed=parseInt(type)
        const newCost=(parsed*10)
        return newCost+" 만원"
    }
}
