import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { addDoc, collection, getDocs, serverTimestamp } from 'firebase/firestore';
import { db } from '../../Firebase/Firebase';

function CreateViceDialog({ open, onClose }) {
    const [viceId, setViceId] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    
    useEffect(() => {
        if (open) {
            generateViceId();
        }
    }, [open]);

    const generateViceId = async () => {
        const viceCollection = collection(db, 'vice');
        const viceSnapshot = await getDocs(viceCollection);
        const count = viceSnapshot.size;

        // 최소 6자리의 숫자 생성
        const idNumber = (count).toString().padStart(6, '0');
        setViceId(`VC${idNumber}`);
    };

    const handleCreate = async () => {
        if (!name || !password) {
            alert('모든 항목을 입력하세요.');
            return;
        }

        try {
            await addDoc(collection(db, 'vice'), {
                id: viceId,
                name: name,
                password: password,
                createdAt: serverTimestamp(),
            });
            alert('부대표가 성공적으로 생성되었습니다!');
            onClose();
        } catch (error) {
            console.error('Error adding vice:', error);
            alert('부대표 생성에 실패했습니다.');
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>부대표 생성</DialogTitle>
            <DialogContent>
             
                <Typography sx={{
                    height:"50px",
                    textIndent:"10px"
                }}>아이디 : {viceId}</Typography>
                <TextField
                    label="이름"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    margin="dense"
                />
                <TextField
                    label="비밀번호"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    margin="dense"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>취소</Button>
                <Button onClick={handleCreate} color="primary">생성</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateViceDialog;
