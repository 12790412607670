import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';

const AudioUpload = ({handleFileChange,handleUploadClick,audioFile,audioSrc}) => {


  return (
    <div>
      <Typography variant="h6">녹음 파일 업로드</Typography>

      <input
        id="audio-upload"
        type="file"
        accept="audio/*"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />

      <Button variant="contained"
      sx={{
        width:"100%"
      }}
      onClick={handleUploadClick}>
        파일 선택
      </Button>

      {audioFile && (
        <div style={{ marginTop: '20px' }}>
          <Typography>파일 이름: {audioFile.name}</Typography>
          <audio controls>
            <source src={audioSrc} type={audioFile.type} />
            Your browser does not support the audio tag.
          </audio>
        </div>
      )}
    </div>
  );
};

export default AudioUpload;
