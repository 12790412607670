import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import DaumPostcode from "react-daum-postcode";

const AddressForm = ({isOpen,onClose,onOpen,handleComplete}) => {


//   

  // DaumPostcode에서 주소 데이터를 받아 처리하는 함수


  return (
    <div>
      <Button 
      fullWidth
      variant='contained' onClick={onOpen}>지역 선택하기</Button>


      {/* 주소 검색 모달 */}
      <Dialog  open={isOpen} onClose={onClose}  >
      <DialogTitle>주소 검색</DialogTitle>
       <DialogContent>
         <DaumPostcode onComplete={handleComplete} />
       </DialogContent>
       {/* <DialogActions>
       <Button variant="outlined" onClick={onClose}>닫기</Button>
       <Button variant="contained" onClick={}>저장</Button>
       </DialogActions> */}
       
      </Dialog>
    </div>
  );
};

export default AddressForm;
