import { Button, CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { StyledTableCell, StyledTableRow } from '../../const/style';
import { formatDate, getGender, getMonthCost } from '../../const/func';
import { FaPencilAlt } from "react-icons/fa";
import { receiptOptions, statusOptions } from '../../const/value';
import { getDownloadURL, ref } from 'firebase/storage'; // Firebase Storage에서 URL 가져오기
import { storage } from '../../Firebase/Firebase'; // Firebase 초기화 파일에서 Storage 가져오기
import '../../Content/Member.css'
import { green1 } from '../../const/colors';
import EditMemberDialog from '../Dialog/EditMemberDialog';
function MemberTable({ datas, handleRowClick , tableContainerRef,handleSearchAll }) {
    const [audioURLs, setAudioURLs] = useState({}); // 오디오 URL을 저장하는 상태
const [urlLoading,setUlrLoading]=useState(false)
const [editRow,setEditRow]=useState(null)
const loginType = localStorage.getItem("loginType");
const onOpenEdit=(row)=>{
    setEditRow(row)
  }
  const onCloseEdit=()=>{
    setEditRow(null)
    handleSearchAll()
  }
    // 오디오 URL 가져오기 함수
    const fetchAudioURL = async (audioSrc) => {
        try {
            const audioRef = ref(storage, `audios/${audioSrc}`);
            const audioURL = await getDownloadURL(audioRef);
            return audioURL;
        } catch (error) {
            console.error("오디오 파일을 가져오는 중 오류 발생:", error);
            return null;
        }
    };

    // 데이터 로드 시 오디오 파일 URL 가져오기
    useEffect(() => {
        const loadAudioURLs = async () => {
            setUlrLoading(true)
            const urls = {};
            for (let row of datas) {
                if (row.audioSrc) {
                    const url = await fetchAudioURL(row.audioSrc);
                    if (url) {
                        urls[row.audioSrc] = url;
                    }
                }
            }
            setAudioURLs(urls);   
            setUlrLoading(false)
        };

        loadAudioURLs();
    }, [datas]);

    return (
        <TableContainer 
        ref={tableContainerRef}
            variant='outlined'
            component={Paper}
            sx={{maxHeight: '650px', overflowY: 'auto',overflowX:"auto" }}
        >
            <EditMemberDialog open={editRow!=null} onMemberClose={onCloseEdit} row={editRow}/>
            <Table sx={{ width: 2000 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="left">No.</StyledTableCell>
                        <StyledTableCell align="left">대리점</StyledTableCell>
                        <StyledTableCell align="left">FC</StyledTableCell>
                        <StyledTableCell align="left">고객명</StyledTableCell>
                        <StyledTableCell align="left">연락처</StyledTableCell>
                        <StyledTableCell align="left">주번</StyledTableCell>
                        <StyledTableCell align="left">성별</StyledTableCell>
                        <StyledTableCell align="left">시/도</StyledTableCell>
                        <StyledTableCell align="left">시/군/구</StyledTableCell>
                        <StyledTableCell align="left">읍/면/동</StyledTableCell>
                        <StyledTableCell align="left">월보험료</StyledTableCell>
                        <StyledTableCell align="left">접수유형</StyledTableCell>
                        <StyledTableCell align="left">진행상태</StyledTableCell>
                        <StyledTableCell align="left">녹취</StyledTableCell>
                        <StyledTableCell align="left">메모</StyledTableCell>
                       <StyledTableCell align="left">수정</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {datas.length === 0 ? (
                        <TableRow>
                            <TableCell colSpan={14} align="center">
                                데이터 없음
                            </TableCell>
                        </TableRow>
                    ) : (
                        datas.map((row, idx) => (
                            <StyledTableRow key={idx} sx={{ cursor: "pointer" }}>
                                <StyledTableCell align="left">{idx + 1}</StyledTableCell>
                                <StyledTableCell align="left">{row.company ?? "-"}</StyledTableCell>
                                <StyledTableCell align="left">{row.fc ?? "-"}</StyledTableCell>
                                <StyledTableCell align="left">{row.member ?? "-"}</StyledTableCell>
                                <StyledTableCell align="left">{row.phone ?? "-"}</StyledTableCell>
                                <StyledTableCell align="left">{row.num ?? "-"}</StyledTableCell>
                                <StyledTableCell align="left">{getGender(row.gender) ?? "-"}</StyledTableCell>
                                <StyledTableCell align="left">{row.address.sido ?? "-"}</StyledTableCell>
                                <StyledTableCell align="left">{row.address.sigungu ?? "-"}</StyledTableCell>
                                <StyledTableCell align="left">{row.address.eupmyeon ?? "-"}</StyledTableCell>
                                <StyledTableCell align="left">{getMonthCost(row.monthCost) ?? "-"}</StyledTableCell>
                                <StyledTableCell align="left">
                                    {receiptOptions.filter((r) => r.value === row.receipt)[0]?.label ?? "-"}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {statusOptions.filter((s) => s.value === row.status)[0]?.label ?? "-"}
                                </StyledTableCell>
                                <StyledTableCell align="left" >
                                   {audioURLs[row.audioSrc]? <div
                                    className='audio-container'
                                    >
                                        {/* 오디오 플레이어 */}
                                    {audioURLs[row.audioSrc] ? (
                                        <audio controls>
                                            <source src={audioURLs[row.audioSrc]} type="audio/mpeg" />
                                            브라우저가 오디오 태그를 지원하지 않습니다.
                                        </audio>
                                    ) : <CircularProgress  sx={{
                                        color:green1
                                       }}/>}
                                    </div>:<div >없음</div>}
                                    
                                </StyledTableCell>
                                <StyledTableCell align="left">{row.memo ?? "-"}</StyledTableCell>
                        <StyledTableCell align="left" ><IconButton
             
             onClick={()=>{onOpenEdit(row)}}
             ><FaPencilAlt/></IconButton></StyledTableCell>
                            </StyledTableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default MemberTable;
