export const monthCostOptions=[
    {value:"0",
    label:"없음"},
    {value:"1",
     label:"10만원대"},
     {value:"2",
        label:"20만원대"},
        {value:"3",
            label:"30만원대"},
            {value:"4",
                label:"40만원대"},
                {value:"5",
                    label:"50만원대"},
                    {value:"6",
                        label:"60만원대"},
                        {value:"7",
                            label:"70만원대"},
                            {value:"8",
                                label:"80만원대"},
                                {value:"9",
                                    label:"90만원대"},
                                    {value:"10",
                                        label:"100만원대"},

]
export const receiptOptions=[
    // {value:"0",
    //     label:"없음"},
        {value:"1",
            label:"웹방송"},
            {value:"2",
                label:"고령자"},
                {value:"3",
                    label:"전화"},
                    {value:"4",
                        label:" 방문"},

]
export const statusOptions=[
    {value:"1",
        label:"배정"},
        {value:"2",
            label:"불성실콜"},
            {value:"3",
                label:"3대질환"},
                {value:"4",
                    label:"설계사"},
                    {value:"5",
                        label:"장기입원자"},
                        {value:"6",
                            label:"오기재"},
]
export const ITEMS_PER_PAGE =10; // 페이지당 항목 수